<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";

import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";
import { API_CONFIG } from "@/config.js";

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
});

const form = ref({
  email: '',
  password: '',
  device_name: window.navigator.userAgent
})
const token = ref(null)
const errorMessage = ref(null)
const errors = ref(null)

// `submitForm` is a normal function
const submitForm = () => {
  let formData = form.value;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(form.value) // access the value with `.value`
  }
  fetch(`${API_CONFIG.baseURL}/api/v1/login`, requestOptions)
      .then(async response => {
        const data = await response.json()
        if (!data.token) {
          return Promise.reject(data)
        }

        token.value = data.token
        localStorage.setItem('token', token.value)
        router.push({ name: 'account' })
      })
      .catch(error => {
        if (error.code === 1) {
          errors.value = error.errors
        } else {
          errorMessage.value = error
          console.error('There was an error!', error)
        }

      })
}

const goToSignUp = () => {
  router.push({ name: 'Signup' });
};
</script>
<template>

  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-basic.jpg&quot;);
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2 mb-3">Sign in</h5>
                <div class="btn-wrapper text-center">
                  <a
                    href="javascript:;"
                    class="btn btn-neutral btn-icon btn-sm mb-0 me-1"
                  >
                    <img
                      class="w-30"
                      src="../../../assets/img/logos/github.svg"
                    />
                    Github
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-neutral btn-icon btn-sm mb-0"
                  >
                    <img
                      class="w-30"
                      src="../../../assets/img/logos/google.svg"
                    />
                    Google
                  </a>
                </div>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <div class="text-center text-muted mb-4">
                  <small>Or sign in with credentials</small>
                </div>
                <form role="form" class="text-start">
                  <div class="mb-3">
                    <argon-input
                      id="email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                      v-model="form.email" name="email"

                    />
                    <span class="text-danger" v-if="errors?.email">{{ errors.email[0] }}</span>
                  </div>
                  <div class="mb-3">
                    <argon-input
                      id="password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      v-model="form.password" name="password"
                    />
                    <span class="text-danger" v-if="errors?.password">{{ errors.password[0] }}</span>
                  </div>
                  <argon-switch id="rememberMe" name="rememberMe">
                    Remember me
                  </argon-switch>

                  <div class="text-center">
                    <argon-button
                      color="success"
                      variant="gradient"
                      full-width
                      class="my-4 mb-2"
                      @click.prevent="submitForm"
                      >Sign in</argon-button
                    >
                  </div>
                  <div class="mb-2 position-relative text-center">
                    <p
                      class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                    >
                      or
                    </p>
                  </div>
                  <div class="text-center">
                    <argon-button
                      color="dark"
                      variant="gradient"
                      full-width
                      class="mt-2 mb-4"
                      @click="goToSignUp"
                      >Sign up</argon-button
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
