<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter(); // Получаем объект маршрута

const id = ref(null);

id.value = router.currentRoute.value.params.id; // Получаем id из объекта маршрута

</script>


<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="col-12 col-lg-12 mx-auto mb-12">
              <div class="card">
                <div class="card-body">
                  /client/{{id}}<br>
                  Страница описания клиента, если включен режим партнерки по клиентам: <strong>{{id}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
