<script setup>
import { ref } from "vue";
import About from "./components/About.vue";
import Pack from "./components/Pack.vue";
import Schedule from "./components/Schedule.vue";
const activeClass = "js-active position-relative";
const activeStep = ref(0);
const formSteps = 2;

const nextStep = () => {
  if (activeStep.value < formSteps) {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
    activeStep.value += 1;
  }
};
const prevStep = () => {
  if (activeStep.value > 0) {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
    activeStep.value -= 1;
  }
};

</script>
<template>
  <div class="py-2 h-100 container-fluid">
    <div class="row">
      <div class="text-center col-12">
        <h3 class="mt-5 text-white d-none d-sm-block">Зарегиструйте свой бизнес</h3>
        <h5 class="text-white font-weight-normal d-none d-sm-block">
          Заполните форму, чтобы мы добавили к нам в приложение – это быстро.
        </h5>
        <div class="multisteps-form" style="margin-bottom: 3.5rem !important;">
          <div class="row">
            <div class="m-auto col-12 col-lg-8">
              <form class="multisteps-form__form">
                <!--single form panel-->
                <about v-if="activeStep === 0" @next-step="nextStep" />
                <!--single form panel-->
                <pack
                    :class="activeStep === 1 ? activeClass : ''"
                    @next-step="nextStep"
                    @prev-step="prevStep"
                />
                <!--single form panel-->
                <schedule
                    :class="activeStep === 2 ? activeClass : ''"
                    @prev-step="prevStep"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
