<script setup>
</script>
<template>
  <div className="container-fluid py-5">
    <div className="row">
      <div className="col-12">
        <div className="multisteps-form">
          <div className="row">
            <div className="col-12 col-lg-12 mx-auto mb-12">
              <div className="card">
                <div className="card-body">
                  /seller/dish<br>
                  Добавление или редактирование блюда
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
