<script setup>
import router from "@/router";
import {nextTick, onUnmounted, ref, onMounted, reactive, require, watch} from 'vue';
import {useStore} from "vuex";
import ArgonBadge from "@/components/ArgonBadge.vue";
const store = useStore();
import { API_CONFIG } from "@/config.js";
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const renderComponent = ref(true);
const selectedCategory = ref(null);
const displayType = ref('list')
const searchText = ref(localStorage.getItem('searchProductText') || '');
watch(selectedCategory, (newVal) => {
  localStorage.setItem('filterByType', newVal);
  if (displayType.value === 'list') {
    getProductsList();
  } else {
    productsForMap();
  }
});

watch(searchText, (newVal) => {
  localStorage.setItem('searchProductText', newVal);
  if (displayType.value === 'list') {
    getProductsList();
  } else {
    productsForMap();
  }
});
const savedCategory = localStorage.getItem('filterByType');
if(savedCategory) {
  selectedCategory.value = savedCategory;
}
const forceRender = async () => {
  // Here, we'll remove MyComponent
  renderComponent.value = false;

  // Then, wait for the change to get flushed to the DOM
  await nextTick();

  // Add MyComponent back in
  renderComponent.value = true;
};
import { MapboxMap, MapboxImage, MapboxCluster, MapboxPopup } from '@studiometa/vue-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// Определение размера экрана
const screenWidth = ref(window.innerWidth);
const product = ref()

// Слушатель изменения размера окна для обновления значения screenWidth
window.addEventListener('resize', () => {
  screenWidth.value = window.innerWidth;
});
const goto = async (id) => {
  if (screenWidth.value < 576) {

    let token = localStorage.getItem('token');
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    }
    let responseProduct = await fetch(`${API_CONFIG.baseURL}/api/v1/products/${id}`, requestOptions)
    let productData = await responseProduct.json()
    product.value = productData.data
    console.log(product);
    modalKey.value++;

    openModal() ;
  } else {
    router.push({ name: 'infoAboutOffer', params: { id: id }})
  }
};
const showModal = ref(false);

const openModal = () => {
  showModal.value = true;
  modalKey.value++;
};
const isActiveToday = ref(false);
const isActiveTomorrow = ref(false);
const closeModal = () => {
  showModal.value = false;
};

const mapboxAccessToken = API_CONFIG.MAPBOX_TOKEN;
const geojson = ref([]);
const locations = ref([]);
const categories = ref([]);
const products = ref([]);
const productsMap = ref([]);
const selectedCity = ref({"lat": 0, "lon": 0});


onMounted(async () => {
  let response = await fetch(`${API_CONFIG.baseURL}/api/v1/product_types`)
  let data = await response.json()
  categories.value = data.data
  categories.value.unshift({"id": 0, "name": "All"});

  await getProductsList();
  selectedCity.value = JSON.parse(localStorage.getItem('selectedCity')) || { lat: 0, lon: 0 };
})

const getProductsList = async () =>  {
  let token = localStorage.getItem('token');
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  }
  let params = [];
  // Есть ли фильтр в локалсторадж
  if (localStorage.getItem('filterByType')) {
    params.push('filterByType=' + encodeURIComponent(localStorage.getItem('filterByType')));
  }
  // Есть ли фильтр в локалсторадж
  if (localStorage.getItem('searchProductText')) {
    params.push('searchProductText=' + encodeURIComponent(localStorage.getItem('searchProductText')));
  }
  params.push('needFavourite=yes');
  let responseProducts = await fetch(`${API_CONFIG.baseURL}/api/v1/products?` + params.join('&'), requestOptions)
  let productsData = await responseProducts.json()
  products.value = productsData.data
}
const switchToList = async() => {
  displayType.value = 'list';
  await getProductsList();
}

const switchToMap = async() => {
  await productsForMap();

  displayType.value = 'map';
}


const productsForMap = async() => {
  let token = localStorage.getItem('token');
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  }
  let params = [];
  // Есть ли фильтр в локалсторадж
  if (localStorage.getItem('filterByType')) {
    params.push('filterByType=' + encodeURIComponent(localStorage.getItem('filterByType')));
  }
  // Есть ли фильтр в локалсторадж
  if (localStorage.getItem('searchProductText')) {
    params.push('searchProductText=' + encodeURIComponent(localStorage.getItem('searchProductText')));
  }
  params.push('needFavourite=yes');
  let responseProductsMap = await fetch(`${API_CONFIG.baseURL}/api/v1/productsMap?` + params.join('&'), requestOptions)
  let productsDataMap = await responseProductsMap.json()
  productsMap.value = productsDataMap.data
  geojson.value = {
    type: "FeatureCollection",
    features: productsMap.value.map(product => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [parseFloat(product.longitude), parseFloat(product.latitude)]
      },
      properties: {
        id: product.id,
        name: product.name,
        description: product.description,
        logo: product.logo,
        address: product.address,
        phone: product.phone,
        latitude: product.latitude,
        longitude: product.longitude,
        products: product.products
      }
    }))
  }
  locations.value = geojson

}


const favProduct = async (id) => {
  let token = localStorage.getItem('token');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  }
  let responseFavProduct = await fetch(`${API_CONFIG.baseURL}/api/v1/user/fav_product/${id}`, requestOptions)
  let favProduct = await responseFavProduct.json()
  if (favProduct.data.success === true) {
    let productIndex = products.value.findIndex((product) => product.id === id);
    if (productIndex !== -1) {
      let product = products.value[productIndex];
      // Создаем новый объект с обновленными свойствами
      let updatedProduct = { ...product, is_favourite: !product.is_favourite };
      // Заменяем старый объект на новый в массиве
      products.value.splice(productIndex, 1, updatedProduct);
    }
  }
};


let cityOptions = {};
let cityData = {};
const errorMessage = ref(null)
const errors = ref(null)
const state = reactive({
  city: '',
  latitude: '',
  longitude: '',
  error: '',
});
const handleCitySelection = (selectedCityData) => {

  // Store the selected city's details in localStorage
  localStorage.setItem('selectedCity', JSON.stringify(cityData[selectedCityData.value]));

  // Empty the cityOptions array once a city is selected,
  // to clear the search results from the UI
  cityOptions.value = [];

  // optionally, you can populate the input field with the selected city's name
  state.searchQuery = selectedCityData.label;
};
const getLocationData = async () => {
  try {
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const { ip } = await ipResponse.json();
    const geoResponse = await fetch(`${API_CONFIG.baseURL}/api/v1/ip-api?ip=${ip}`);
    const locationData = await geoResponse.json();
    cityData[locationData.city] = {
      value: locationData.city,
      label: locationData.city,
      lat: locationData.lat,
      lon: locationData.lon
    };

    handleCitySelection({
      value: locationData.city,
      label: locationData.city,
      lat: locationData.lat,
      lon: locationData.lon
    });
  } catch (error) {
    console.error('There was an error getting location data', error);
  }
};
const getMyLocation = () => {

  if (!navigator.geolocation) {
    state.error = t('account.geoNotWorking');
  }
  navigator.geolocation.getCurrentPosition(position => {
    const { latitude, longitude } = position.coords;

    fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=e6c11fec2a3b4b8580a80ab3d03f25a7`)
        .then(response => response.json())
        .then(data => {
          state.city = data.results[0].components.city;
          state.latitude = data.results[0].geometry.lat;
          state.longitude = data.results[0].geometry.lng;

          selectedCity.value = { lon: data.results[0].geometry.lng, lat: data.results[0].geometry.lat };
          const result = data.results[0];
          cityData = {
            label: result.components.city,
            lat: result.geometry.lat,
            lon: result.geometry.lng,
          };

          localStorage.setItem('selectedCity', JSON.stringify(cityData));

        })
        .catch(error => {
          getLocationData();
        });
  }, () => {
    getLocationData();
  });
};
const isOpen = ref(false);
const position = ref([0, 0]);
const showPopup = ref(false);
const content = ref();

async function openPopup({ geometry, properties }) {
  await nextTick();
  position.value = [...geometry.coordinates];

  isOpen.value = true;
  showPopup.value = true;

  content.value = Object.fromEntries(
      Object.entries(properties).map(([key, value]) => {
        try {
          return [key, JSON.parse(value)];
        } catch (err) {
          // Silence is golden.
        }

        return [key, value];
      })
  );

}
const modalKey = ref(0)

</script>

<style type="text/css">
.btn-toggle.active {
  background-color: #007bff;
}
.popup-div {
  /* Style your popup div */
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.hover-bg-image {
  background-size: cover;
  background-position: center;
  transition: background-position 0.3s;
}
.cursor-pointer:hover .hover-bg-image {
  background-position: center 1px; /* Сдвигает изображение вниз на 5 пикселей при наведении на родительский див с классом cursor-pointer */
}
.dropdown-toggle-split {
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important;
}
.dropdown-toggle:after {
  vertical-align: 1.255em !important;
}
</style>

<style>
.d-flex {
  display: flex;
}
.flex-grow-1 {
  flex-grow: 1;
}
.me-2 {
  margin-right: 0.5rem;
}
.square-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square-button i {
  font-size: 1rem;
}
.square-container {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.cat-container {
  width: 120px !important;
  flex-shrink: 0;
  margin-right: 0.5rem;
}
@media (max-width: 576px) {

  .d-flex {
    flex-wrap: nowrap;
  }
  .flex-grow-1 {
    flex-basis: 0;
    flex-grow: 1;
  }
  .me-2 {
    margin-right: 0.5rem;
  }
}
</style>

<template>
  <div class="modal" :key="modalKey" :class="{ 'd-block': showModal }">
    <!-- Картинка сверху модального окна -->
    <div class="top-image" :style="{ 'background-image': 'url(' + product?.photos[0]?.path || ''  + ')' }">
      <div class="position-absolute top-0 end-0 m-2 mt-2">
        <div class="rounded-circle cursor-pointer" style="background-color: rgba(0, 0, 0, 0.2);">
          <i class="fas fa-heart fs-5 p-3" :class="{'text-white': product?.is_favourite}" @click.stop="favProduct( product?.id )"></i>
        </div>
      </div>

      <div class="position-absolute start-0 m-3 mb-2">
        <span class="bg-white rounded-3 p-0 ps-2 pe-2 small fw-bold" style="margin-top: 120px; display: block">{{ product?.quantity }} left</span>
      </div>
      <div class="position-absolute start-0 m-3 mb-2">
        <h5 class="text-white fw-bold"
            style="background-color: rgba(0, 0, 0, 0.2); margin-top: 150px;">
          {{ product?.name }}
        </h5>
      </div>
      <div class="position-absolute top-0 start-0 m-2 mt-1">
        <div class="rounded-circle cursor-pointer" style="background-color: rgba(0, 0, 0, 0.2);" @click="closeModal">
          <i class="fas fa-arrow-left text-white fs-5 p-3"></i>
        </div>
      </div>
    </div>

    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-body">
          <div class="mt-3">
            <div class="card-body pt-0 position-relative">
              <div class="position-absolute top-0 end-0 m-3 mt-0 text-end">
                <div class="p-0 m-0 small text-decoration-line-through">{{ product?.old_price }}</div>
                <div class="p-0 m-0 fw-bold fs-4">{{ product?.price }}</div>
              </div>
              <div class="d-flex align-items-center mb-1">
                <div class="text-center w-6">
                  <i class="fa fa-shopping-bag text-lg opacity-6"></i>
                </div>
                <div class="my-auto ms-3">
                  <div class="h-100">
                    <p class="text-sm mb-1">{{ product?.sale_place?.name || '' }}</p>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center mb-1">
                <div class="text-center w-6">
                  <i class="fa fa-star text-lg opacity-6"></i>
                </div>
                <div class="my-auto ms-3">
                  <div class="h-100">
                    <p class="text-sm mb-1">{{ product?.rating }} <span class="text-secondary">(28)</span></p>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="text-center w-6">
                  <i class="fa fa-clock text-lg opacity-6"></i>
                </div>
                <div class="my-auto ms-3">
                  <div class="h-100">
                    <p class="text-sm mb-1">
                      Pick up:
                      <argon-badge variant="gradient" color="success" size="sm" style="margin-left: 8px">
                        {{ product?.pickup }}
                      </argon-badge>
                    </p>
                  </div>
                </div>
              </div>
              <hr class="horizontal dark" />
              <div class="d-flex align-items-center">
                <div class="text-center w-6">
                  <i class="fa fa-map-marker text-lg opacity-6"></i>
                </div>
                <div class="my-auto ms-3">
                  <div class="h-100">
                    <p class="text-sm mb-1 me-2">
                      {{ product?.sale_place?.address || '' }}
                    </p>
                    <p class="text-xs mb-1 me-2 text-primary">
                      Show on maps
                    </p>
                  </div>
                </div>
                <div class="my-auto me-1">
                  <div class="text-center w-6">
                    <i class="fa fa-map-o text-lg opacity-6 text-primary"></i>
                  </div>
                </div>
              </div>
              <hr class="horizontal dark" />
              <div class="align-items-center">
                <div class="my-auto ms-0 mb-2">
                  <div class="h-100">
                    <h6 class="modal-title text-sm">
                      What you could get
                    </h6>
                  </div>
                </div>
                <div class="my-auto ms-0">
                  <div class="h-100">
                    <p class="text-sm mb-1 me-2">
                      {{ product?.description }}
                    </p>
                    <span v-for="productType in product?.types" :key="productType.id">
                      <argon-badge variant="gradient" color="secondary" size="sm" style="margin-right: 8px">
                      {{ productType.name }}
                    </argon-badge>
                    </span>

                  </div>
                </div>
              </div>
              <hr class="horizontal dark" />
              <div class="align-items-center">
                <div class="my-auto ms-0 mb-2">
                  <div class="h-100">
                    <h6 class="modal-title text-sm">
                      Payment
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="d-block d-sm-none fixed-top container-fluid" style="background: rgba(248, 249, 250, 0.9) !important;">
    <div class="row mt-3">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="col-12 col-lg-12 mx-auto mb-0">
              <form>
                <div class="d-flex flex-wrap align-items-center">
                  <div class="flex-grow-1 me-2">
                    <div class="input-group input-group-sm">
                      <input type="text" class="form-control search_input" v-model="searchText" style="border-color: #2dce89 !important;" placeholder="Поиск">
                    </div>
                  </div>
                  <div class="d-flex justify-content-center cat-container">
                    <select class="form-select form-select-sm" v-model="selectedCategory" style="border-color: #2dce89 !important; color: #2dce89; font-weight: bold">
                      <option
                          v-for="category in categories"
                          :key="category.id"
                          :value="category.id"
                      >
                        {{category.name}}
                      </option>

                    </select>
                  </div>
                  <div class="d-flex align-items-center justify-content-center square-container">
                    <button type="button" class="d-block d-sm-none btn btn-outline-success square-button"
                            @click.prevent="getMyLocation"
                            style="margin-bottom: 0 !important; padding: 0.1625rem 0rem;">
                      <i class="fas fa-map-marker-alt fa-lg"></i>
                    </button>
                    <button type="button" class="d-none d-sm-block btn btn-info square-button"
                            @click.prevent="getMyLocation"
                            style="margin-bottom: 0 !important; padding: 0.1625rem 0rem;">
                      <i class="fas fa-map-marker-alt fa-lg"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="mt-3 mt-sm-3 text-sm">
          <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic mixed styles example">
            <button type="button" class="btn" :class="displayType === 'list' ? 'btn-success' : 'btn-outline-success'"  style="height: 32px; line-height: 0.3px;" @click="switchToList">List</button>
            <button type="button" class="btn" :class="displayType === 'map' ? 'btn-success' : 'btn-outline-success'"  style="height: 32px; line-height: 0.3px;" @click="switchToMap">Map</button>
          </div>
        </div>
        <div class="mt-0 mt-sm-3 mb-2 mb-sm-2 text-sm">
          Sort by distance from:
          <span>
              <span class="dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="font-weight-bold">the center </span>
                <span class="visually-hidden">Toggle Dropdown</span>
              </span>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">the center</a></li>
                <li><a class="dropdown-item" href="#">me</a></li>
              </ul>
            </span>
        </div>
      </div>
    </div>
  </div>
  <section>
    <div class="mt-1 py-2 container-fluid">
      <!-- карта -->


      <div class="map-layout" style="display: none" v-show="displayType === 'map'">
        <div>
          <MapboxMap
              v-if="renderComponent && selectedCity"
              style="height: 600px"
              zoom="12"
              :access-token="mapboxAccessToken"
              :center="[selectedCity.lon, selectedCity.lat]"
              map-style="mapbox://styles/mapbox/streets-v11">

            <MapboxCluster :data="locations.value"  @mb-feature-click="openPopup"/>
          </MapboxMap>
        </div>
        <div v-if="showPopup" class="popup-div" style="
                z-index: 1000000 !important;
                position: absolute;
                color: red;
            ">
          <h2>!!!!!!!!!!{{ content.name }}</h2>
          <p>{{ content.description }}</p>
        </div>



      </div>
      <!-- список -->
      <div class="row list-layout" style="margin-top: 130px;" v-for="product in products" :key="product.id" v-show="displayType === 'list'">


        <div class="mt-1 sm-4 col-12 col-md-4 col-xl-4">

          <div class="card h-100 d-flex flex-column cursor-pointer">

            <div class="card-img-top img-fluid bg-cover bg-center position-relative hover-bg-image"
                 style="min-height: 150px;"
                 :style="{ 'background-image': 'url(' + product.photos[0].path || ''  + ')' }"  @click="goto( product.id )">
              <div class="position-absolute top-0 start-0 m-2 mt-1">
                <span class="bg-white rounded-3 p-0 ps-2 pe-2 small fw-bold">{{ product.quantity }} left</span>
              </div>
              <div class="position-absolute top-0 end-0 m-2 mt-2">
                <div class="rounded-circle" style="background-color: rgba(0, 0, 0, 0.2);">

                  <i class="fas fa-heart fs-5 p-3" :class="{'text-white': product.is_favourite}" @click.stop="favProduct( product.id )"></i>

                </div>
              </div>
              <h5 class="position-absolute bottom-0 start-0 m-3 mb-2 text-white fw-bold" style="background-color: rgba(0, 0, 0, 0.2);"  @click="goto( product.id )">
                {{ product.name }}
              </h5>
            </div>
            <div class="card-body pt-2 pb-2 p-3 position-relative flex-grow-1" @click="goto( product.id )">
              <h6 class="mb-0">{{ product.sale_place?.name || '' }}</h6>
              <p class="small">Pick up {{ product.pickup }}</p>
              <p class="mb-0">
                <i class="fas fa-star"></i> <span class="fw-bold">{{ product.rating }}</span>
                <span class="ms-3 small fw-bold">{{ product.distance }}</span>
              </p>
              <div class="position-absolute bottom-0 end-0 m-2 text-end">
                <div class="p-0 m-0 small text-decoration-line-through">{{ product.old_price }}</div>
                <div class="p-0 m-0 fw-bold fs-4">{{ product.price }}</div>
              </div>
            </div>
          </div>
        </div>



      </div>
      <div class="d-block d-sm-none div65">&nbsp;</div>
    </div>

  </section>
</template>
<style scoped>

/* Стили для полноэкранного модального окна */
.div65 {
  margin-bottom: 3.5rem !important;
}
.modal {
  display: none;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Предотвращает скроллинг основного контента */
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.btn-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  color: black; /* Цвет крестика */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Тень */
}

.btn-close:hover {
  text-decoration: none;
  opacity: 0.75;
}

.top-image {
  height: 200px; /* Высота картинки */
  background-size: cover;
  background-position: center;
}
</style>