import { reactive } from 'vue';

// Создаем реактивное состояние
const userAuth = reactive({
    email: '',
    city: '',
    latitude: '',
    longitude: '',
    authtype: '',
    code: ''
});

// Создаем методы для изменения состояния
const setEmail = (newEmail) => {
    userAuth.email = newEmail;
};
const setCity = (newCity) => {
    userAuth.city = newCity;
};

const setLatitude = (newLatitude) => {
    userAuth.latitude = newLatitude;
};

const setLongitude = (newLongitude) => {
    userAuth.longitude = newLongitude;
};
const setAuthType = (newAuthType) => {
    userAuth.authtype = newAuthType;
};
const setCode = (newCode) => {
    userAuth.code = newCode;
};

// Экспортируем состояние и методы
export { userAuth, setEmail, setCity, setLatitude, setLongitude, setAuthType, setCode };
