<script setup>
import { onBeforeMount, onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'

const { t } = useI18n() // 't' это функция для выполнения перевода
import * as Choices from "choices.js";
import RightLayout from "@/views/wf/auth/components/RightLayout.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import LangBlock from "@/examples/components/Lang.vue";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});
onMounted(() => {

  if (document.getElementById("choices-day")) {
    let day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day, {allowHTML: true});
    }, 1);

    for (let y = 1; y <= 31; y++) {
      let optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }
});
</script>
<style scoped>
.login-img {
  height: 35px;
}
.login-a {
  margin-left: 10px;
  margin-right: 10px;
}
.justify-content-lg-end {
  justify-content: center !important;
}
</style>
<template>
  <main class="mt-0 main-content">
    <div class="page-header min-vh-100">
      <div class="container">
        <div class="row">





          <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 text-center justify-content-center position-relative flex-column my-auto d-lg-flex"
          >

            <div class="mx-auto text-center" style="width: 200px">
              <img style="border: 0px !important;" src="@/assets/img/logo-ct-dark.svg" class="mb-3" :style="{ backgroundSize: 'cover', height: '150px', width: '150px' }" />
            </div>



            <div class="card card-plain text-center">
              <div class="pb-0 card-header text-start text-center">
                <h4 class="font-weight-bolder">Привет, Maksim Trushin!</h4>
                <p class="mb-3 text-center">
                  Укажите ваш email.
                </p>
                <argon-input
                    id="email"
                    type="email"
                    placeholder="Email"
                    name="email"
                    size="lg"
                />
                <argon-button
                    class="mb-2"
                    variant="gradient"
                    color="success"
                    full-width
                    size="lg"
                >Next</argon-button>

              </div>
            </div>

            <div>
              <lang-block></lang-block>
            </div>
          </div>

          <right-layout></right-layout>


        </div>
      </div>
    </div>
  </main>
</template>
