<script setup>
import { onBeforeMount, onBeforeUnmount, onMounted, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
const { t } = useI18n() // 't' это функция для выполнения перевода
import * as Choices from "choices.js";
import RightLayout from "@/views/wf/auth/components/RightLayout.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { API_CONFIG } from "@/config.js";


import { useRouter } from "vue-router";
const router = useRouter();

import LangBlock from "@/examples/components/Lang.vue";


const body = document.getElementsByTagName("body")[0];

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});
onMounted(() => {

  if (document.getElementById("choices-day")) {
    let day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day, {allowHTML: true});
    }, 1);

    for (let y = 1; y <= 31; y++) {
      let optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }
});
const errorMessage = ref(null)
const errors = ref(null)
const saveLocation = async () => {
  // Получить данные из localStorage

  const selectedCityData = JSON.parse(localStorage.getItem('selectedCity'));

  if (selectedCityData) {
    // Преобразование данных в нужный формат
    const cityKey = Object.keys(selectedCityData)[0];
    const selectedCity = selectedCityData[cityKey];
    const dataToSend = {
      city: selectedCity.label,
      latitude: selectedCity.lat,
      longitude: selectedCity.lon
    };

    let token = localStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(dataToSend)
    }
    // Отправить данные
    let response = await fetch(`${API_CONFIG.baseURL}/api/v1/user/city`, requestOptions)
        .then(async response => {
          const data = await response.json()
          if (data.data.id) {
            router.push({name: 'offers'});
          }
        })
        .catch(error => {
          if (error.code === 1) {
            errors.value = error.errors
          } else {
            errorMessage.value = error
            console.error('There was an error!', error)
          }

        })

  } else {
    console.log('No data in localStorage with key "selectedCity"');
  }
};


const state = reactive({
  city: '',
  latitude: '',
  longitude: '',
  error: '',
});
let cityData = {};
const getMyLocation = () => {
  if (!navigator.geolocation) {
    router.push({name: "AuthCity"});
  }
  navigator.geolocation.getCurrentPosition(position => {
    const { latitude, longitude } = position.coords;

    fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=e6c11fec2a3b4b8580a80ab3d03f25a7`)
        .then(response => response.json())
        .then(data => {
          state.city = data.results[0].components.city | data.results[0].components.town;
          state.latitude = data.results[0].geometry.lat;
          state.longitude = data.results[0].geometry.lng;

          const result = data.results[0];
          cityData[result.components.city] = {
            label: result.components.city,
            lat: result.geometry.lat,
            lon: result.geometry.lng,
          };

          localStorage.setItem('selectedCity', JSON.stringify(cityData));
          saveLocation();

        })
        .catch(error => {
          router.push({name: "AuthCity"});
        });
  }, () => {
    router.push({name: "AuthCity"});
  });
};


</script>
<style scoped>
.login-img {
  height: 35px;
}
.login-a {
  margin-left: 10px;
  margin-right: 10px;
}
.justify-content-lg-end {
  justify-content: center !important;
}
</style>
<template>
  <main class="mt-0 main-content">
    <div class="page-header min-vh-100">
      <div class="container">
        <div class="row">





          <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 text-center justify-content-center position-relative flex-column my-auto d-lg-flex"
          >

            <div class="mx-auto text-center" style="width: 200px">
              <img style="border: 0px !important;" src="@/assets/img/logo-ct-dark.svg" class="mb-3" :style="{ backgroundSize: 'cover', height: '150px', width: '150px' }" />
            </div>



            <div class="card card-plain text-center">
              <div class="pb-0 card-header text-start text-center">
                <h4 class="font-weight-bolder">Доступ к локации</h4>
                <p class="mb-3 text-center">
                  Предоставьте нам доступ к локации чтобы показывать предложения
                </p>
                <form role="form">

                  <argon-button
                      class="mb-2"
                      variant="gradient"
                      color="success"
                      full-width
                      size="lg"
                      @click.prevent="getMyLocation"
                  >Далее</argon-button>
                </form>
              </div>
            </div>

            <div>
              <lang-block></lang-block>
            </div>
          </div>

          <right-layout></right-layout>


        </div>
      </div>
    </div>
  </main>
</template>
