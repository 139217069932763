<script setup>
import {onBeforeMount, onBeforeUnmount, ref} from "vue";
import {useStore} from "vuex";

import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import router from "@/router";
import { API_CONFIG } from "@/config.js";

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
});

// `form`, `token`, and `errorMessage` are now reactive references
const form = ref({
  name: '',
  email: '',
  password: '',
  device_name: window.navigator.userAgent,
  is_owner: false,
  is_partner: false,
  check_terms: true
})
const token = ref(null)
const errorMessage = ref(null)
const errors = ref(null)

// `submitForm` is a normal function
const submitForm = () => {
  let formData = form.value;

  let ref_date = localStorage.getItem('ref_date');
  let ref_link = localStorage.getItem('ref_link');

  if(ref_date && ref_link) {
    let red_date = new Date(ref_date);
    let currentDate = new Date();

    if(red_date < currentDate) {
      formData.ref_link = ref_link;
    }
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(form.value) // access the value with `.value`
  }
  fetch(`${API_CONFIG.baseURL}/api/v1/register`, requestOptions)
      .then(async response => {
        const data = await response.json()
        if (!data.token) {
          return Promise.reject(data)
        }

        token.value = data.token
        localStorage.setItem('token', token.value)
        router.push({ name: 'account' })
      })
      .catch(error => {
        if (error.code === 1) {
          errors.value = error.errors
        } else {
          errorMessage.value = error
          console.error('There was an error!', error)
        }

      })
}

</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur border-radius-lg py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-basic.jpg&quot;);
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container mt-4">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0">


              <div class="card-body">
                <form role="form">
                  <argon-input id="name" v-model="form.name" name="name" type="text" placeholder="Name" aria-label="Name" />
                  <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                  <argon-input id="email" v-model="form.email" name="email" type="email" placeholder="Email" aria-label="Email" />
                  <span class="text-danger" v-if="errors?.email">{{ errors.email[0] }}</span>
                  <argon-input id="password" v-model="form.password" name="password" type="password" placeholder="Password" aria-label="Password" />
                  <span class="text-danger" v-if="errors?.password">{{ errors.password[0] }}</span>
                  <argon-switch id="is_owner" :checked="form.is_owner" @change="(checked) => form.is_owner = checked ? true : false" v-model="form.is_owner" name="is_owner" type="checkbox" placeholder="I am owner" aria-label="I am owner" label="" /> I am owner
                  <span class="text-danger" v-if="errors?.is_owner">{{ errors.is_owner[0] }}</span>
                  <argon-switch id="is_partner"  :checked="form.is_partner" @change="(checked) => form.is_partner = checked ? true : false" v-model="form.is_partner" name="is_partner" type="checkbox" placeholder="I am partner" aria-label="I am partner" />I am partner
                  <span class="text-danger" v-if="errors?.is_partner">{{ errors.is_partner[0] }}</span>
                  <argon-checkbox id="check_terms" :checked="form.check_terms" @change="(checked) => form.check_terms = checked ? true : false" v-model="form.check_terms" name="check_terms"  >
                    <label class="form-check-label" for="check_terms">
                      I agree the
                      <a href="javascript:;" class="text-dark font-weight-bolder">Terms and Conditions</a>
                    </label>
                    <span class="text-danger" v-if="errors?.check_terms">{{ errors.check_terms[0] }}</span>
                  </argon-checkbox>
                  <div class="text-center">
                    <argon-button type="submit" @click.prevent="submitForm" full-width color="dark" variant="gradient" class="my-4 mb-2">Sign up</argon-button>
                  </div>
                  <p class="text-sm mt-3 mb-0"> Already have an account?
                    <router-link :to="{ name: 'Signin' }" class="text-dark font-weight-bolder">
                      Sign in
                    </router-link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
