<script setup>
import { ref, onMounted } from "vue";
import {useI18n} from "vue-i18n";
const { t } = useI18n() // 't' это функция для выполнения перевода
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import { API_CONFIG } from "@/config.js";
import  { userData, setNameUser, setSnameUser, setEmailUser, setAvatarUser, setPhoneUser, setCityUser, setIsPartner, setIsOwner } from '@/store/userStore.js';

// Загрузка аватара
let uploader = ref(null)

const onAvatarClick = () => {
  uploader.value.click()
}

const sendImageApi = async (event) => {
  // Получение выбранного файла
  const file = event.target.files[0]
  // Создание FormData объекта для передачи файла
  let formData = new FormData()
  formData.append('avatar', file)
  const token = localStorage.getItem('token')

  // Отправка файла на сервер через API
  const response = await fetch(`${API_CONFIG.baseURL}/api/v1/user/avatar`, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${token}`},
    body: formData
  })

  if (!response.ok) {
    throw new Error('Failed to upload image: ' + response.statusText)
  }

  // Обновление изображения в ответе от сервера
  const data = await response.json()
  if (data.data.avatar_path) {
    setAvatarUser(`${API_CONFIG.baseURL}` + data.data.avatar_path);
  } else {
    console.log('verify email');
  }

}

async function getUserInfo() {
  const requestGetOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  };
  const response = await fetch(`${API_CONFIG.baseURL}/api/v1/me`, requestGetOptions);
  const data = await response.json();
  setNameUser(data.data.name);
  setSnameUser(data.data.sname);
  setEmailUser(data.data.email);
  setPhoneUser(data.data.phone);
  setCityUser(data.data.city);
  setAvatarUser(`${API_CONFIG.baseURL}` + data.data.avatar_path);
  setIsPartner(data.data.is_partner);
  setIsOwner(data.data.is_owner);

}

onMounted(() => {

  getUserInfo(); // вызываем нашу функцию при монтировании компонента

});
</script>
<style scoped>
.card {
  border-radius: 0 !important;
}
</style>
<template>
  <section>
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <argon-avatar
            :image="userData.avatar"
            alt="team-3"
            size="xl"
            shadow="sm"
            border-radius="lg"
            @click="onAvatarClick"
        />
        <input type="file"
               ref="uploader"
               name="avatar"
               @change="sendImageApi"
               accept="image/*"
               style="display: none" />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ userData.name }} {{ userData.sname }}</h5>
          <p class="mb-0 font-weight-bold text-sm">{{ userData.email }}</p>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">

      </div>
    </div>
  </section>
</template>