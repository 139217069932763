<script setup>

import { useI18n } from 'vue-i18n'
const { t } = useI18n() // 't' это функция для выполнения перевода

import changePassword from "@/views/wf/account/components/changePassword.vue";
import router from "@/router";
import ProfileAccount from "@/views/wf/account/components/profileAccount.vue";
import {onMounted, ref} from "vue";
import {API_CONFIG} from "@/config";

let name = 'Maksim'; // создаем реактивные свойства для хранения информации о пользователе
let sname = 'Trushin';
let email = 'max@trushin.me';

const goTo = () => {
  router.push({ name: 'account'})
};
</script>
<template>
  <section>


    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">

          <div id="profile" class="card card-body mt-4">
            <profile-account :name="name" :sname="sname" :email="email" />
          </div>

        </div>
      </div>
    </div>
  </section>
</template>