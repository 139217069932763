<script setup>
import { useStore } from "vuex";
const store = useStore();

const closeSidebarOnRouteChange = () => {
  store.state.isPinned = false;
};

defineProps({
  to: {
    type: [Object, String],
    required: true,
  },
  miniIcon: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <li class="nav-item">
    <router-link class="nav-link" :to="to" @click="closeSidebarOnRouteChange">
      <div
          class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
      >
        <slot name="icon"></slot>
      </div>
      <span class="nav-link-text"> {{ text }} </span>
    </router-link>
  </li>
</template>
