<script setup>
import router from "@/router";

defineProps({
  btnBackground: {
    type: String,
    default: "",
  },
  isBlur: {
    type: String,
    default: "",
  },
  darkMode: {
    type: Boolean,
    default: false,
  },
});

const goto = (id) => {
  router.push({ name: id})
};
</script>
<template>
  <!-- Navbar -->
  <nav
    class="navbar navbar-expand-lg top-0 z-index-3 position-absolute mt-1"
    :class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'"
  >
    <div class="container pb-5 pb-lg-5 pt-0 pe-0">
      <router-link
        class="navbar-brand font-weight-bolder ms-lg-0 ms-3"
        :class="darkMode ? 'text-black' : 'text-white'"
        to="/"
        style="font-size: 1.125rem !important;"
        >
        <img style="border: 0px !important;" src="@/assets/img/logo-ct-white.svg" class="mb-1" :style="{ backgroundSize: 'cover', height: '45px', width: '45px', 'padding-right': '6px' }" />
        WoFooo.com
      </router-link
      >
      <button
        class="shadow-none navbar-toggler ms-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navigation"
        aria-controls="navigation"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="mt-2 navbar-toggler-icon">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div
        id="navigation"
        class="pt-3 collapse navbar-collapse w-100 py-lg-0"
      >
        <ul class="mx-auto navbar-nav navbar-nav-hover">
          <li class="mx-2 nav-item dropdown dropdown-hover">
            <a
                role="button"
                @click="goto('TermsConditions')"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkMode && 'text-dark'"
                aria-expanded="false"
            >
              Terms & Conditions
            </a>
          </li>
          <li class="mx-2 nav-item dropdown dropdown-hover">
            <a
                role="button"
                @click="goto('PrivacyPolicy')"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkMode && 'text-dark'"
                aria-expanded="false"
            >
              Privacy Policy
            </a>
          </li>
          <li class="mx-2 nav-item dropdown dropdown-hover">
            <a
                role="button"
                @click="goto('CookiePolicy')"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkMode && 'text-dark'"
                aria-expanded="false"
            >
              Cookie Policy
            </a>
          </li>
          <li class="mx-2 nav-item dropdown dropdown-hover">
            <a
                role="button"
                @click="goto('PromotionReferralTerms')"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkMode && 'text-dark'"
                aria-expanded="false"
            >
              Promotion and Referral Terms
            </a>
          </li>
          <li class="mx-2 nav-item dropdown dropdown-hover">
            <a
                role="button"
                @click="goto('DataDeletionInstructions')"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkMode && 'text-dark'"
                aria-expanded="false"
            >
              Data Deletion Instructions
            </a>
          </li>
        </ul>

      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>
