<script setup>
import { ref, watch } from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { useI18n } from 'vue-i18n'
import  { userData } from '@/store/userStore.js';
import { API_CONFIG } from "@/config.js";
const { t } = useI18n() // 't' это функция для выполнения перевода

const errorMessage = ref(null)
const errors = ref(null)
const formUserUpdate = ref({
  name: '',
  phone: '',
  sname: ''
})
watch(userData, (newVal) => {
  formUserUpdate.value.name = newVal.name;
  formUserUpdate.value.phone = newVal.phone;
  formUserUpdate.value.sname = newVal.sname;
}, { immediate: true });
const token = localStorage.getItem('token')
const submitFormUpdateUser = () => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    body: JSON.stringify(formUserUpdate.value) // access the value with `.value`
  }
  fetch(`${API_CONFIG.baseURL}/api/v1/user/profile`, requestOptions)
      .then(async response => {
        const data = await response.json()
        if (data.data.id) {
          alert('GOOD');
        }
      })
      .catch(error => {
        if (error.code === 1) {
          errors.value = error.errors
        } else {
          errorMessage.value = error
          console.error('There was an error!', error)
        }

      })
}
</script>
<template>
  <section>
      <div class="card-header">
        <h6 class="mb-0">{{ t('account.basicUserInfo') }}</h6>
      </div>
      <div class="card-body pt-0">
        <form role="form" id="formUserUpdate">
        <label class="form-label">{{ t('account.firstName') }}</label>
        <argon-input id="name" name="name" v-model="formUserUpdate.name" type="text" :placeholder="$t('account.nameExample')" />
          <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
        <label class="form-label">{{ t('account.lastName') }}</label>
        <argon-input id="sname" name="sname" v-model="formUserUpdate.sname" type="text" :placeholder="$t('account.lastNameExample')" />
          <span class="text-danger" v-if="errors?.sname">{{ errors.sname[0] }}</span>
        <label class="form-label mt-2">{{ t('account.phoneNumber') }}</label>
        <argon-input id="phone" name="phone" v-model="formUserUpdate.phone" type="text" :placeholder="$t('account.phoneExample')" />
          <span class="text-danger" v-if="errors?.phone">{{ errors.phone[0] }}</span>
        <argon-button
            class="float-end mt-2 mb-3"
            color="dark"
            variant="gradient"
            size="sm"
            @click.prevent="submitFormUpdateUser"
            type="submit"
        >{{ t('account.updateInfo') }}</argon-button>
        </form>

      </div>
  </section>
</template>