<script setup>
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
const { t } = useI18n() // 't' это функция для выполнения перевода
import * as Choices from "choices.js";
import { API_CONFIG } from "@/config.js";
import RightLayout from "@/views/wf/auth/components/RightLayout.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { useRouter } from "vue-router";
const router = useRouter();

import LangBlock from "@/examples/components/Lang.vue";


const body = document.getElementsByTagName("body")[0];

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});
onMounted(() => {

  if (document.getElementById("choices-day")) {
    let day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day, {allowHTML: true});
    }, 1);

    for (let y = 1; y <= 31; y++) {
      let optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }
});


const form = ref({
  email: '',
  token: '',
  code: '',
  device_name: window.navigator.userAgent
})
const token = ref(null)
const errorMessage = ref(null)
const errors = ref(null)

const submitForm = () => {
  let formData = form.value;
  let codeElement = document.getElementById('code');
  if (!codeElement) {
    throw new Error('No code input found');
  }

  formData.code = codeElement.value;
  formData.email = localStorage.getItem('tempEmail')

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(form.value) // access the value with `.value`
  }
  fetch(`${API_CONFIG.baseURL}/api/v1/login`, requestOptions)
      .then(async response => {
        const data = await response.json()
        if (data.code === 1) {
          errors.value = data.errors
        } else {
          errorMessage.value = data
          console.error('There was an error!', data)
        }
        if (!data.token) {
          return Promise.reject(data)
        }


        token.value = data.token
        localStorage.setItem('token', token.value)
        router.push({ name: 'AuthLocation' })
      })
      .catch(error => {
        if (error.code === 1) {
          errors.value = error.errors
        } else {
          errorMessage.value = error
          console.error('There was an error!', error)
        }

      })
}

</script>
<style scoped>
.login-img {
  height: 35px;
}
.login-a {
  margin-left: 10px;
  margin-right: 10px;
}
.justify-content-lg-end {
  justify-content: center !important;
}
</style>
<template>
  <main class="mt-0 main-content">
    <div class="page-header min-vh-100">
      <div class="container">
        <div class="row">





          <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 text-center justify-content-center position-relative flex-column my-auto d-lg-flex"
          >

            <div class="mx-auto text-center" style="width: 200px">
              <img style="border: 0px !important;" src="@/assets/img/logo-ct-dark.svg" class="mb-3" :style="{ backgroundSize: 'cover', height: '150px', width: '150px' }" />
            </div>



            <div class="card card-plain text-center">
              <div class="pb-0 card-header text-start text-center">
                <h4 class="font-weight-bolder">Проверочный код</h4>
                <p class="mb-3 text-center">
                  Мы отправили проверочный код вам на емейл.
                </p>
                <form role="form">
                <argon-input
                    id="code"
                    type="text"
                    placeholder="Code"
                    v-model="code"
                    name="code"
                    size="lg"
                />
                  <span class="text-danger" v-if="errors?.code">{{ errors.code[0] }}</span>
                <argon-button
                    class="mb-2"
                    variant="gradient"
                    color="success"
                    full-width
                    size="lg"
                    @click.prevent="submitForm"
                >Отправить</argon-button>
                </form>
              </div>
            </div>

            <div>
              <lang-block></lang-block>
            </div>
          </div>

          <right-layout></right-layout>


        </div>
      </div>
    </div>
  </main>
</template>
