<script setup>

import {ref} from "vue";
import {useRouter} from "vue-router";

const id = ref(null);
const router = useRouter(); // Получаем объект маршрута

id.value = router.currentRoute.value.params.id; // Получаем id из объекта маршрута

</script>
<template>
  <div className="container-fluid py-5">
    <div className="row">
      <div className="col-12">
        <div className="multisteps-form">
          <div className="row">
            <div className="col-12 col-lg-12 mx-auto mb-12">
              <div className="card">
                <div className="card-body">
                  /seller/point<br>
                  Добавление или редактирование точки продаж {{id}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
