<script setup>
import { ref,watch, onMounted } from "vue";
import {useI18n} from "vue-i18n";
const { t } = useI18n() // 't' это функция для выполнения перевода
const i18n = useI18n({ useScope: 'global' });
import LangBlock from "@/examples/components/Lang.vue";
import  { userData } from '@/store/userStore.js';

// Инициализация состояния на основе значения из userStore
const menuCheck = ref({
  is_owner: userData.is_owner,
  is_partner: userData.is_partner
});

watch(() => userData.is_owner, (newVal) => {
  menuCheck.value.is_owner = newVal;
});
import router from "@/router";
const goTo = (page) => {
  router.push({ name: page})
};
const setLanguage = (lang) => {
  i18n.locale.value = lang; // Изменяем текущую локаль
};
</script>
<style scoped>
  .fa {
    width: 15px;
    text-align: center;
  }
  .card {
    border-radius: 0 !important;
  }

</style>
<template>
  <section>
    <div class="card top-1 mt-2">
      <ul class="nav flex-column bg-white border-radius-lg p-3">
        <li class="nav-item pt-0">
          <a
              class="nav-link text-body d-flex align-items-center cursor-pointer"
              data-scroll
              @click="goTo('basicInfo')"
          >
            <i class="fa fa-info me-2 text-dark opacity-6"></i>
            <span class="ms-2 text-sm">{{ t('account.basicUserInfo') }}</span>
          </a>
        </li>
        <li class="nav-item pt-2">
          <a
              class="nav-link text-body d-flex align-items-center cursor-pointer"
              data-scroll
              @click="goTo('affiliateProgram')"
          >
            <i class="fa fa-eur me-2 text-dark opacity-6"></i>
            <span class="ms-2 text-sm">{{ t('account.affiliateProgram') }}</span>
          </a>
        </li>
        <li class="nav-item pt-2">
          <a
              class="nav-link text-body d-flex align-items-center cursor-pointer"
              data-scroll
              @click="goTo('changeCity')"
          >
            <i class="fa fa-map me-2 text-dark opacity-6"></i>
            <span class="ms-2 text-sm">{{ t('account.updateCity') }}</span>
          </a>
        </li>
        <li class="nav-item pt-2">
          <a
              class="nav-link text-body d-flex align-items-center cursor-pointer"
              data-scroll
              @click="goTo('haveAPoint')"
          >
            <i class="fa fa-map-marker me-2 text-dark opacity-6"></i>
            <span class="ms-2 text-sm">{{ t('account.haveAPoint') }}</span>
          </a>
        </li>
        <li class="nav-item"><hr></li>
        <li class="nav-item pt-0">
          <a
              class="nav-link text-body d-flex align-items-center cursor-pointer"
              data-scroll
              @click="goTo('haveAPoint')"
          >
            <i class="fa fa-map-marker me-2 text-dark opacity-6"></i>
            <span class="ms-2 text-sm">My store</span>
          </a>
        </li>
        <li class="nav-item"><hr></li>
        <li class="nav-item">
          <a
              class="nav-link text-body d-flex align-items-center"
              data-scroll
              href="/policy/terms-conditions" target="_blank"
          >
            <span class="text-sm">{{ t('footer.terms') }}</span>
          </a>
        </li>
        <li class="nav-item pt-2">
          <a
              class="nav-link text-body d-flex align-items-center"
              data-scroll
              href="/policy/privacy" target="_blank"
          >
            <span class="text-sm">{{ t('footer.privacy') }}</span>
          </a>
        </li>
        <li class="nav-item pt-2">
          <a
              class="nav-link text-body d-flex align-items-center"
              data-scroll
              href="/policy/cookie" target="_blank"
          >
            <span class="text-sm">{{ t('footer.cookie') }}</span>
          </a>
        </li>
        <li class="nav-item pt-2">
          <a
              class="nav-link text-body d-flex align-items-center"
              data-scroll
              href="/policy/promotion-referral" target="_blank"
          >
            <span class="text-sm">{{ t('footer.affiliate') }}</span>
          </a>
        </li>
        <li class="nav-item pt-2">
          <a
              class="nav-link text-body d-flex align-items-center"
              data-scroll
              href="/policy/data-deletion" target="_blank"
          >
            <span class="text-sm">{{ t('footer.delete') }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div
        class="text-sm text-center copyright text-muted mt-3"
    >
      ©
      {{ new Date().getFullYear() }}, made with
      <i class="fa fa-heart text-danger"></i> by
      WoFooo.com
    </div>
    <lang-block></lang-block>
    <div style="height: 65px;"></div>
  </section>
</template>