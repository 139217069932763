<script setup>
import { onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import setNavPills from "@/assets/js/nav-pills.js";
import router from "@/router";
import { useI18n } from 'vue-i18n'
const { t } = useI18n() // 't' это функция для выполнения перевода

const store = useStore();

onMounted(() => {
  store.state.showSidenav = false;
  store.state.showNavbar = false;
  store.state.showFooter = false;
  setNavPills();
});
onBeforeUnmount(() => {
  store.state.showSidenav = true;
  store.state.showNavbar = true;
  store.state.showFooter = true;
  if (store.state.isPinned === false) {
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    store.state.isPinned = true;
  }
});
const goto = (id) => {
  router.push({ name: 'AuthMethod'})
};
</script>
<style>
img {
  border: none !important;
}
</style>
<template>
  <div class="page-header position-relative vh-100 d-flex flex-column justify-content-center">
    <div class="container pb-10 pb-lg-9 pt-7 position-relative z-index-2">
      <div class="row">
        <div class="mx-auto text-center col-md-6 mt-4" style="border: 0px">
          <img style="border: 0px !important;" src="@/assets/img/logo-ct-dark.png" class="mb-3" :style="{ backgroundSize: 'cover', height: '150px', width: '150px' }" />
          <div class="mx-auto text-center" style="width: 200px">
            <h2 style="font-size: 2.25rem">{{ t('index.slogan') }}</h2>
          </div>
          <p class="text-black-50">
            <span class="small">{{ t('index.with') }}</span>
          </p>
          <p class="mt-5">
            <a style="color: #00A4FF; cursor: pointer" @click="goto(2)">{{ t('index.more') }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
