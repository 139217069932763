<script setup>
import { defineComponent, reactive, ref, onMounted, watch } from 'vue';
import ArgonButton from "@/components/ArgonButton.vue";
import  { userData } from '@/store/userStore.js';
import { API_CONFIG } from "@/config.js";
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { useI18n } from 'vue-i18n'
const { t } = useI18n() // 't' это функция для выполнения перевода

let cityChoices;
let cityData = {};
const errorMessage = ref(null)
const geocodingClient = mbxGeocoding({ accessToken: API_CONFIG.MAPBOX_TOKEN});
const errors = ref(null)
const saveLocation = async () => {
  // Получить данные из localStorage

  const selectedCityData = JSON.parse(localStorage.getItem('selectedCity'));

  if (selectedCityData) {
    // Преобразование данных в нужный формат

    const dataToSend = {
      city: selectedCityData.label,
      latitude: selectedCityData.lat,
      longitude: selectedCityData.lon
    };

    let token = localStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(dataToSend)
    }
    // Отправить данные
    let response = await fetch(`${API_CONFIG.baseURL}/api/v1/user/city`, requestOptions)
        .then(async response => {
          const data = await response.json()
          if (data.data.id) {
            alert('GOOD');
          }
        })
        .catch(error => {
          if (error.code === 1) {
            errors.value = error.errors
          } else {
            errorMessage.value = error
            console.error('There was an error!', error)
          }

        })

  } else {
    console.log('No data in localStorage with key "selectedCity"');
  }
};

const getMyLocation = () => {

  if (!navigator.geolocation) {
    state.error = t('account.geoNotWorking');
  }
  navigator.geolocation.getCurrentPosition(position => {
    const { latitude, longitude } = position.coords;

    fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=e6c11fec2a3b4b8580a80ab3d03f25a7`)
        .then(response => response.json())
        .then(data => {
          state.city = data.results[0].components.city;
          state.latitude = data.results[0].geometry.lat;
          state.longitude = data.results[0].geometry.lng;

          const result = data.results[0];
          cityData = {
            label: result.components.city,
            lat: result.geometry.lat,
            lon: result.geometry.lng,
          };

          localStorage.setItem('selectedCity', JSON.stringify(cityData));

        })
        .catch(error => {
          state.error = t('account.geoNotWorking');
        });
  }, () => {
    state.error = t('account.geoNeedAccess')
  });
};
const cityOptions = ref([]);
const state = reactive({
  city: '',
  latitude: '',
  longitude: '',
  error: '',
});
const searchCity = (inputValue) => {
  if (inputValue.length < 3) {
    return;
  }
  const lang = localStorage.getItem('language');
  geocodingClient.forwardGeocode({
    query: inputValue,
    autocomplete: true,
    types: ['place'],
    language: [lang]
  })
      .send()
      .then(response => {
        const predictions = response.body.features;
        const options = predictions.map(prediction => {
          cityData[prediction.place_name] = {
            label: prediction.place_name,
            lat: prediction.center[1],
            lon: prediction.center[0],
          };
          return {
            value: prediction.place_name,
            label: prediction.place_name,
          };
        });
        cityOptions.value = options;
        if (options.length > 0) {
          const selectedCity = options[0];
          localStorage.setItem('selectedCity', JSON.stringify(cityData[selectedCity.value]));
        }
      })
      .catch(error => {
        console.error('Error with Mapbox API: ', error);
      });

};
watch(() => state.city, (newCity) => {
  if (newCity) {
    alert(`Selected city: ${newCity}`);
  }
});
onMounted(() => {

});

</script>
<template>
  <section>
    <div class="card-header">
      <h6 class="mb-0">{{ t('account.myCity') }}</h6>
    </div>
    <div class="card-body pt-0">
      <a href="#" class="btn" @click.prevent="getMyLocation">{{ $t('account.getMyLocation') }}</a>
      <div v-if="state.error" class="alert alert-danger">{{ state.error }}</div>
      <form role="form" id="formCityUserUpdate">
      <label class="form-label">{{ t('account.currentCity') }}</label>

        <v-select
            v-model="state.city"
            :options="cityOptions"
            @search="searchCity"
            label-by="label"
            value-by="value"
            :searchable="true"
            :clearable="true"
            placeholder="Type to search"
        ></v-select>
      <span class="text-danger" v-if="errors?.city">{{ errors.city[0] }}</span>

      <argon-button
          class="float-end mt-4 mb-3 "
          color="dark"
          variant="gradient"
          size="sm"
          @click.prevent="saveLocation"
      >{{ t('account.updateCity') }}</argon-button>
      </form>
    </div>
  </section>
</template>