<template>
  <div
      class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
  >
  <div
    class="position-relative bg-gradient-success h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
    style="
                background-image: url(&quot;/bg.jpg&quot;);
                background-size: cover;
              "
>
    <!--<span class="mask bg-dark opacity-6"></span>-->
    <h4 class="mt-5 text-white font-weight-bolder position-relative">
      WoFooo
    </h4>
    <p class="text-white position-relative">
      Buy food in stores, restaurants, and cafes with discounts of up to 70 percent.
    </p>
    </div>
      </div>
    </template>