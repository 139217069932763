<script setup>
import { ref,watch, onMounted } from "vue";
import { API_CONFIG } from "@/config.js" ;

import router from "@/router";
import  { userData, setNameUser, setSnameUser, setEmailUser, setAvatarUser, setPhoneUser, setCityUser, setIsPartner, setIsOwner } from '@/store/userStore.js';


async function getUserInfo() {
  const requestGetOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  };
  const response = await fetch(`${API_CONFIG.baseURL}/api/v1/me`, requestGetOptions);
  const data = await response.json();
  setNameUser(data.data.name);
  setSnameUser(data.data.sname);
  setEmailUser(data.data.email);
  setPhoneUser(data.data.phone);
  setCityUser(data.data.city);
  setAvatarUser(`${API_CONFIG.baseURL}` + data.data.avatar_path);
  setIsPartner(data.data.is_partner);
  setIsOwner(data.data.is_owner);

}

onMounted(() => {

  getUserInfo(); // вызываем нашу функцию при монтировании компонента

});

// Инициализация состояния на основе значения из userStore
const menuCheck = ref({
  is_owner: userData.is_owner,
  is_partner: userData.is_partner
});

watch(() => userData.is_owner, (newVal) => {
  menuCheck.value.is_owner = newVal;
});

const goTo = (page) => {
  router.push({ name: page})
};
defineProps({
  currentPage: {
    type: String,
    default: "",
  },
  currentDirectory: {
    type: String,
    default: "",
  },
});

</script>
<style scoped>
.navbar {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
</style>
<template>
  <nav class="navbar navbar-light bg-light fixed-bottom footer-menu-navbar">
    <div class="container-fluid footer-menu">
      <div class="d-flex justify-content-between w-100">
        <div class="nav-item">
          <a class="nav-link cursor-pointer" @click="goTo('offers')">
            <i class="ni ni-spaceship text-sm opacity-10"
               :class="{ 'text-success': currentDirectory === 'Offers' && currentPage !== 'history' }"
            ></i>
            <span class="small mt-1"
                  :class="{ 'text-success': currentDirectory === 'Offers' && currentPage !== 'history' }"
            >{{$t('menu.offers')}}</span>
          </a>
        </div>
        <div class="nav-item">
          <a class="nav-link cursor-pointer" @click="goTo('favorite')">
            <i class="ni ni-favourite-28 text-sm opacity-10"
               :class="{ 'text-danger': currentDirectory === 'Favorite' }"
            ></i>
            <span class="small mt-1"
                  :class="{ 'text-danger': currentDirectory === 'Favorite' }"
              >{{$t('menu.favorite')}}</span>
          </a>
        </div>
        <div class="nav-item" v-if="!menuCheck.is_owner && !menuCheck.is_partner">
          <a class="nav-link cursor-pointer" @click="goTo('history')">
            <i class="ni ni-books text-sm opacity-10"
               :class="{ 'text-primary': currentPage === 'history' }"
            ></i>
            <span class="small mt-1"
                  :class="{ 'text-primary': currentPage === 'history' }"
            >{{$t('menu.history')}}</span>
          </a>
        </div>
        <div class="nav-item" v-if="menuCheck.is_owner">
          <a class="nav-link cursor-pointer" @click="goTo('seller')">
            <i class="ni ni-books text-sm opacity-10"
               :class="{ 'text-primary': currentPage === 'seller' }"
            ></i>
            <span class="small mt-1"
                  :class="{ 'text-primary': currentPage === 'seller' }"
            >My store</span>
          </a>
        </div>
        <div class="nav-item" v-if="menuCheck.is_partner && !menuCheck.is_owner">
          <a class="nav-link cursor-pointer" @click="goTo('affiliateProgram')">
            <i class="ni ni-books text-sm opacity-10"
               :class="{ 'text-primary': currentPage === 'affiliateProgram' }"
            ></i>
            <span class="small mt-1"
                  :class="{ 'text-primary': currentPage === 'affiliateProgram' }"
            >Affiliate</span>
          </a>
        </div>
        <div class="nav-item cursor-pointer">
          <a class="nav-link" @click="goTo('account')">
            <i class="ni ni-single-02 text-sm opacity-10"
               :class="{ 'text-warning': currentDirectory === 'Account' }"
            ></i>
            <span class="small mt-1"
                  :class="{ 'text-warning': currentDirectory === 'Account' }"
            >{{$t('menu.account')}}</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>

/* Ваши стили CSS здесь */
.navbar {
  /* Уменьшаем высоту навигационной панели */
  height: 80px;
}
.footer-menu {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
/*
.footer-menu-navbar {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}*/
.nav-item {
  /* Убираем маргин для пунктов меню */
  margin: 0;
  width: 25%;
}

.nav-link {
  /* Убираем обводку ссылок */
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #343a40; /* Цвет текста */
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: none;
  color: #007bff; /* Цвет при наведении */
}
</style>
