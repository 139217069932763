<script setup>
import { onBeforeMount, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
const { t } = useI18n() // 't' это функция для выполнения перевода
import RightLayout from "@/views/wf/auth/components/RightLayout.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import LangBlock from "@/examples/components/Lang.vue";
import { API_CONFIG } from "@/config.js";

const body = document.getElementsByTagName("body")[0];
import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();

import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");
const geocodingClient = mbxGeocoding({ accessToken: API_CONFIG.MAPBOX_TOKEN});
const getLocationData = async () => {
  try {
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const { ip } = await ipResponse.json();
    const geoResponse = await fetch(`${API_CONFIG.baseURL}/api/v1/ip-api?ip=${ip}`);
    const locationData = await geoResponse.json();
    cityData[locationData.city] = {
      value: locationData.city,
      label: locationData.city,
      lat: locationData.lat,
      lon: locationData.lon
    };
    handleCitySelection({
      value: locationData.city,
      label: locationData.city,
      lat: locationData.lat,
      lon: locationData.lon
    });
  } catch (error) {
    console.error('There was an error getting location data', error);
  }
};
onBeforeMount(async () => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
  await getLocationData();
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});
const cityOptions = ref([]);
const state = reactive({
  searchQuery: '',
  latitude: '',
  longitude: '',
  error: '',
});

let cityChoices;
let cityData = {};

const searchCity = () => {
  if (state.searchQuery.length < 3) {
    return;
  }
  const lang = localStorage.getItem('language');
  geocodingClient.forwardGeocode({
    query: state.searchQuery,
    autocomplete: true,
    types: ['place'],
    language: [lang]
  })
      .send()
      .then(response => {
        const predictions = response.body.features;
        const options = predictions.map(prediction => {
          cityData[prediction.place_name] = {
            label: prediction.place_name,
            lat: prediction.center[1],
            lon: prediction.center[0],
          };
          return {
            value: prediction.place_name,
            label: prediction.place_name,
          };
        });
        cityOptions.value = options;
      })
      .catch(error => {
        console.error('Error with Mapbox API: ', error);
      });
};





const errorMessage = ref(null)
const errors = ref(null)
const saveLocation = async () => {
  // Получить данные из localStorage
  const selectedCityDataString = localStorage.getItem('selectedCity');

  if (selectedCityDataString) {
    const selectedCityData = JSON.parse(selectedCityDataString);
    // Преобразование данных в нужный формат
    const dataToSend = {
      city: selectedCityData.label,
      latitude: selectedCityData.lat,
      longitude: selectedCityData.lon
    };

    let token = localStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(dataToSend)
    };
    // Отправить данные
    let response = await fetch(`${API_CONFIG.baseURL}/api/v1/user/city`, requestOptions)
        .then(async response => {
          const data = await response.json();
          if (data.data.id) {
            router.push( { name: 'offers' } );
          }
        })
        .catch(error => {
          if (error.code === 1) {
            errors.value = error.errors;
          } else {
            errorMessage.value = error;
            console.error('There was an error!', error);
          }
        });
  } else {
    console.log('No data in localStorage with key "selectedCity"');
  }
};

const handleCitySelection = (selectedCityData) => {

  // Store the selected city's details in localStorage
  localStorage.setItem('selectedCity', JSON.stringify(cityData[selectedCityData.value]));

  // Empty the cityOptions array once a city is selected,
  // to clear the search results from the UI
  cityOptions.value = [];

  // optionally, you can populate the input field with the selected city's name
  state.searchQuery = selectedCityData.label;
};
const clearLocalStorage = () => {
  state.searchQuery = '';
  localStorage.removeItem('selectedCity');
};

</script>
<style scoped>
.login-img {
  height: 35px;
}
.login-a {
  margin-left: 10px;
  margin-right: 10px;
}
.justify-content-lg-end {
  justify-content: center !important;
}
.choices[data-type*="select-one"] .choices__input,
.choices[data-type*="select-multiple"] .choices__input {
  border: 2px solid grey;
}

/* Define CSS for the wrapper to make it look like a text field */
.input-wrapper {
  position: relative;
  display: inline-block;
}

/* Define CSS for the clear icon */
.clear-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
}
</style>
<template>
  <main class="mt-0 main-content">
    <div class="page-header min-vh-100">
      <div class="container">
        <div class="row">





          <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 text-center justify-content-center position-relative flex-column my-auto d-lg-flex"
          >

            <div class="mx-auto text-center" style="width: 200px">
              <img style="border: 0px !important;" src="@/assets/img/logo-ct-dark.svg" class="mb-3" :style="{ backgroundSize: 'cover', height: '150px', width: '150px' }" />
            </div>



            <div class="card card-plain text-center">
              <div class="pb-0 card-header text-start text-center">
                <h4 class="font-weight-bolder">{{ t('auth.city')}}</h4>
                <p class="mb-3 text-center">
                  {{ t('auth.cityText')}}
                </p>

                <input
                    v-model="state.searchQuery"
                    @input="searchCity"
                    placeholder="Type to search"
                    class="form-control"
                />
                <button v-if="state.searchQuery" @click="clearLocalStorage" class="clear-icon">
                  &times;
                </button>
              </div>
              <!-- Display Search Results in Divs Below Input Field -->
              <div
                  v-for="option in cityOptions"
                  :key="option.value"
                  v-on:click="handleCitySelection(option)"
                  class="search-result-item"
              >
                {{ option.label }}
              </div>
              <div class="pb-0 card-header text-start text-center">
                <h4 class="font-weight-bolder">{{ t('auth.location')}}</h4>
                <p class="mb-3 text-center">
                  {{ t('auth.locationText')}}
                </p>
                <argon-button
                    class="mb-2"
                    variant="gradient"
                    id="saveLocation"
                    color="success"
                    full-width
                    size="lg"
                    @click="saveLocation"
                >{{ t('auth.next')}}</argon-button>

              </div>
            </div>

            <div>
              <lang-block></lang-block>
            </div>
          </div>

          <right-layout></right-layout>


        </div>
      </div>
    </div>
  </main>
</template>
