<script setup>
import { ref, watch } from "vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { useI18n } from 'vue-i18n';
import { userData, setIsOwner } from '@/store/userStore.js';
const { t } = useI18n(); // 't' это функция для выполнения перевода
import { API_CONFIG } from "@/config.js";

const errorMessage = ref(null);
const errors = ref(null);

// Инициализация состояния на основе значения из userStore
const formOwnerUserUpdate = ref({
  is_owner: userData.is_owner
});

const token = localStorage.getItem('token');

const submitFormUpdateOwnerUser = () => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    body: JSON.stringify(formOwnerUserUpdate.value)
  };

  fetch(`${API_CONFIG.baseURL}/api/v1/user/owner`, requestOptions)
      .then(async response => {
        const data = await response.json();
        if (data.data.id) {
          setIsOwner(formOwnerUserUpdate.value.is_owner); // обновляем значение в userData
        }
      })
      .catch(error => {
        if (error.code === 1) {
          errors.value = error.errors;
        } else {
          errorMessage.value = error;
          console.error('There was an error!', error);
        }
      });
};

// Синхронизация состояния при изменении userData.is_partner
watch(() => userData.is_owner, (newVal) => {
  formOwnerUserUpdate.value.is_owner = newVal;
});

// Обновление userData.is_partner при изменении formAffiliateUserUpdate
watch(formOwnerUserUpdate, (newVal) => {
  setIsOwner(newVal.is_owner);
});

</script>
<template>
  <section>
    <div class="card-header">
      <h6 class="mb-0">{{ t('account.haveAPoint') }}</h6>
    </div>
    <div class="card-body pt-0">
      <label class="form-label mt-2">{{ t('account.sellTheRemaining') }}</label>
      <p class="mb-0 ps-2" style="font-size: 0.9rem">
        {{ t('account.sellTheRemainingText') }}<br>
        <!--<router-link class="mb-0 btn btn-link pe-3 ps-0 ms-auto" to="#">{{ t('account.moreInfo') }}</router-link>-->
      </p>
      <ul class="list-group">
        <li class="px-0 border-0 list-group-item">
          <argon-switch
              id="is_partner"
              name="is_partner"
              value="true"
              v-model="formOwnerUserUpdate.is_owner"
              :checked="formOwnerUserUpdate.is_owner"
              @change="val => { formOwnerUserUpdate.is_owner = val.target.checked; submitFormUpdateOwnerUser(); }"
              label-class="mb-0 text-body ms-3 text-truncate w-80"
          >{{ t('account.enableSellerSection') }}</argon-switch
          >
        </li>
      </ul>
      <label class="form-label mt-3">{{ t('account.configureYourPage') }}</label>
      <p class="mb-0 ps-2" style="font-size: 0.9rem">
        {{ t('account.configureYourPageText') }}
      </p>
    </div>
  </section>
</template>