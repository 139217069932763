<script setup>
import Dropzone from "dropzone";
import {onMounted, ref} from "vue";
import {API_CONFIG} from "@/config.js";

const selectedCategories = ref([]);
const tempPhotos = ref([]);
const selectedPrice = ref();
const description = ref();
const createdId = ref(0);
const packName = ref();
const categories = ref([])
const prices = ref([])

onMounted(async () => {
  const token = localStorage.getItem('token')

  Dropzone.autoDiscover = false;

  var drop = document.getElementById("dropzone");
  var myDropzone = new Dropzone(drop, {
    url: `${API_CONFIG.baseURL}/api/v1/products_temp_photo`,
    addRemoveLinks: true,
    headers: { 'Authorization': `Bearer ${token}` }
  });

  myDropzone.on("success", function(file, response) {
    if (response.data.path) {
      if (tempPhotos.value.includes(response.data.path)) {
        tempPhotos.value = tempPhotos.value.filter(c => c !== response.data.path);
      } else {
        tempPhotos.value.push(response.data.path);
      }
      localStorage.setItem('tempPhotos', JSON.stringify(tempPhotos.value));
    } else {
      console.error('No path in response');
    }
  });


  let response = await fetch(`${API_CONFIG.baseURL}/api/v1/product_types`)
  let data = await response.json()
  categories.value = data.data

  let responsePrices = await fetch(`${API_CONFIG.baseURL}/api/v1/prices`)
  prices.value = await responsePrices.json()
});



const handleCategorySelection = (category) => {
  if (selectedCategories.value.includes(category)) {
    selectedCategories.value = selectedCategories.value.filter(c => c !== category);
  } else {
    selectedCategories.value.push(category);
  }
  console.log(selectedCategories.value); // Add this line
  localStorage.setItem('packCategories', JSON.stringify(selectedCategories.value));
}
const handlePriceSelection = (price) => {
  selectedPrice.value = price;
  localStorage.setItem('selectedPrice', price);
}

const handleDescriptionInput = () => {  // New function for handling description input
  localStorage.setItem('description', description.value);
}
const handleNameInput = () => {  // New function for handling description input
  localStorage.setItem('packName', packName.value);
}

const handleSubmit = async () => {

  const createdPlace = JSON.parse(localStorage.getItem('createdPlace'));
  const placeId = createdPlace && createdPlace.id ? createdPlace.id : null;
  // create an object that includes form values
  const productData = {
    categories: selectedCategories.value,
    description: description.value,
    name: packName.value,
    photo: tempPhotos.value,  // assuming that the photos are in proper format
    price: selectedPrice.value,
    sale_place_id: placeId,
    id: createdId.value
  };

  try {
    // send it to the server
    const response = await fetch(`${API_CONFIG.baseURL}/api/v1/products`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(productData)
    });

    // parse JSON response into native JavaScript objects
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'There was an error submitting the form.');
    }

    // if the data object has 'id' property, store the data in the local storage and navigate to the next page
    if (data.data.id) {
      localStorage.setItem('createdProduct', JSON.stringify(data.data));
      emit('nextStep');  // assuming that this will navigate to the next page
    }
  } catch (error) {
    console.error('There was an error submitting the form:', error);
  }
};
onMounted(async () => {
  if (localStorage.getItem('createdProduct')) {

    let productReady = JSON.parse(localStorage.getItem('createdProduct'));
    createdId.value = productReady.id;
    packName.value = productReady.name;
    description.value = productReady.description;

    selectedCategories.value = JSON.parse(localStorage.getItem('packCategories')) || [];


  }
})

const emit = defineEmits(["nextStep", "prevStep"]);
</script>
<style scoped>
.btn-outline-success, .btn-success {
  margin-right: 6px;
}
</style>
<template>
  <div
      class="mb-4 bg-white card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
  >
    <div class="row p-3 pb-0">
      <div class="mx-auto col-12">
        <h5 class="text-center font-weight-normal">
          Ваш woPack
        </h5>
        <p class="text-sm" style="text-align: left !important;">
          woPack изо дня в день может содержать в себе разную продукцию. Опишите в общих чертах, что там можно найти.
        </p>
      </div>
    </div>
    <hr style="margin-top: 8px !important; margin-bottom: 8px !important;">
    <div class="multisteps-form__content">
      <div class="p-3 pt-0 pb-0">
        <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label class="mb-0" style="margin-left: 0 !important;">Категория woPack</label>
            <p class="text-xs font-italic">Можно выбрать несколько.</p>
            <div>
              <button
                  v-for="category in categories"
                  :key="category.id"
                  :class="[`btn btn-xs`, (selectedCategories || []).includes(category.id) ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handleCategorySelection(category.id)"
              >
                {{category.name}}</button>
            </div>
          </div>
        </div>


        <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label class="mb-1" style="margin-left: 0 !important;">Название</label>
            <input
                class="mb-3 multisteps-form__input form-control"
                type="text"
                placeholder="Название"
                v-model="packName"
                @input="handleNameInput"
            />
            <input
                type="hidden"
                v-show="createdId"
            />
          </div>
        </div>


        <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label class="mb-1" style="margin-left: 0 !important;">Описание</label>
            <textarea
                class="mb-3 multisteps-form__input form-control"
                type="text"
                placeholder="Описание"
                v-model="description"
                @input="handleDescriptionInput"
            />
          </div>
        </div>
        <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label class="mb-0" style="margin-left: 0 !important;">Изображение</label>
            <p class="text-xs font-italic">Соотношение сторон ≈ 16:9, формат: png или jpg.</p>
            <form
                id="dropzone"
                class="form-control dropzone"
            >
              <div class="fallback">
                <input name="photo" type="file" />
              </div>
            </form>
          </div>
        </div>
        <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label class="mb-0" style="margin-left: 0 !important;">Стоимость</label>
            <p class="text-xs font-italic">Наша комиссия 1.29 € за один любой woPack.</p>
            <div>
              <button
                  v-for="price in prices"
                  :key="price"
                  :class="[`btn btn-xs`, (selectedPrice === price) ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handlePriceSelection(price)"
              >
                {{price}}</button>

            </div>
          </div>
        </div>
      </div>

      <div class="p-3 pt-0 pb-0">
        <div class="row text-start">
        </div>
      </div>
      <div class="mt-1 button-row d-flex p-3">

        <button
            class="mb-0 btn bg-gradient-light js-btn-prev"
            type="button"
            title="Prev"
            @click="emit('prevStep')"
        >
          Prev
        </button>
        <button
            class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
            type="button"
            title="Next"
            @click="handleSubmit"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
