<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import {ref, onMounted, computed, reactive, watch} from "vue";
import { API_CONFIG } from "@/config.js";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  }
})

let emit = defineEmits(["prevStep",'update:modelValue']);

const checked = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
});
const schedule = reactive({
  monCheck: true,
  monStart: '17:30',
  monEnd: '19:00',
  tueCheck: true,
  tueStart: '17:30',
  tueEnd: '19:00',
  wedCheck: true,
  wedStart: '17:30',
  wedEnd: '19:00',
  thuCheck: true,
  thuStart: '17:30',
  thuEnd: '19:00',
  friCheck: true,
  friStart: '17:30',
  friEnd: '19:00',
  satCheck: true,
  satStart: '17:30',
  satEnd: '19:00',
  sunCheck: false,
  sunStart: '17:30',
  sunEnd: '19:00',
}) ;

function useScheduleSend() {
  return computed(() => {
    if (!schedule) return {}
    const result = {};
    const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    days.forEach(day => {
      if (schedule[`${day}Check`]) {
        result[day] = {
          time_start: schedule[`${day}Start`],
          time_end: schedule[`${day}End`],
        }
      }
    });
    return result
  });
}

const scheduleSend = useScheduleSend()

watch(schedule, (newSchedule) => {
  console.log('schedule updated:', newSchedule);
  localStorage.setItem('schedule', JSON.stringify(scheduleSend.value)); // записываем новые значения в localStorage
}, {deep: true});

const selectedQuantity = ref();
const handleQuantitySelection = (quantity) => {
  selectedQuantity.value = quantity;
  localStorage.setItem('selectedQuantity', quantity);
}

async function handleSubmit() {
  const url = `${API_CONFIG.baseURL}/api/v1/product_temp_update`; // замените на ваш URL
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  };
  const createdProduct = JSON.parse(localStorage.getItem('createdProduct'));
  const productId = createdProduct && createdProduct.id ? createdProduct.id : null;

  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ quantity: selectedQuantity.value, schedule: scheduleSend.value, product_id: productId }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    let data = await response.json();
    router.push({name: 'orders'});
    console.log(data);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ' + error.message);
  }
}
</script>
<style scoped>
.btn-outline-success, .btn-success {
  margin-right: 6px;
}
.btn {
  margin-bottom: 0.5rem;
}
.row {
  --bs-gutter-x: 0.5rem !important;
}
</style>
<template>
  <div
      class="mb-4 bg-white card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
  >
    <div class="row p-3 pb-0">
      <div class="mx-auto col-12">
        <h5 class="text-center font-weight-normal">
          Расписание
        </h5>
        <p class="text-sm" style="text-align: left !important;">
          Сколько woPack вы готовы продавать в день, по каким дням и в какое время?
        </p>
      </div>
    </div>
    <div class="multisteps-form__content">

      <hr style="margin-top: 0 !important; margin-bottom: 8px !important;">
      <div class="p-3 pt-0 pb-0">
        <div class="row text-start">
          <div class="col-12 position-relative mb-1">
            <label class="mb-2" style="margin-left: 0 !important;">Лимит в день</label>
            <div>
              <button
                  :class="[`btn btn-xs`, (selectedQuantity === '1') ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handleQuantitySelection('1')"
              >1</button>
              <button
                  :class="[`btn btn-xs`, (selectedQuantity === '2') ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handleQuantitySelection('2')"
              >2</button>
              <button
                  :class="[`btn btn-xs`, (selectedQuantity === '3') ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handleQuantitySelection('3')"
              >3</button>
              <button
                  :class="[`btn btn-xs`, (selectedQuantity === '4') ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handleQuantitySelection('4')"
              >4</button>
              <button
                  :class="[`btn btn-xs`, (selectedQuantity === '5') ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handleQuantitySelection('5')"
              >5</button>
              <button
                  :class="[`btn btn-xs`, (selectedQuantity === '6') ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handleQuantitySelection('6')"
              >6</button>
              <button
                  :class="[`btn btn-xs`, (selectedQuantity === '7') ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handleQuantitySelection('7')"
              >7</button>
            </div>
          </div>
        </div>
      </div>
      <hr style="margin-top: 0px !important; margin-bottom: 8px !important;">
      <div class="p-3 pt-0 pb-0">
        <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label style="margin-left: 0 !important;">График</label>

            <div class="row d-flex align-items-center">
              <div class="col-3">
                <argon-checkbox id="checkboxId" :checked="schedule.monCheck" v-model="schedule.monCheck">Mon</argon-checkbox>
              </div>
              <div class="col-4">
                <argon-input type="time" size="md" placeholder="Time" class="mb-0" v-model="schedule.monStart" />
              </div>
              <div class="col-1 text-center">
                -
              </div>
              <div class="col-4">
                <argon-input type="time" placeholder="Time" class="mb-0" v-model="schedule.monEnd" />
              </div>
            </div>

            <div class="row d-flex align-items-center mt-2">
              <div class="col-3">
                <argon-checkbox id="checkboxId" :checked="schedule.tueCheck" v-model="schedule.tueCheck">Tue</argon-checkbox>
              </div>
              <div class="col-4">
                <argon-input type="time" size="md" placeholder="Time" class="mb-0" v-model="schedule.tueStart" />
              </div>
              <div class="col-1 text-center">
                -
              </div>
              <div class="col-4">
                <argon-input type="time" placeholder="Time" class="mb-0" v-model="schedule.tueEnd" />
              </div>
            </div>

            <div class="row d-flex align-items-center mt-2">
              <div class="col-3">
                <argon-checkbox id="checkboxId" :checked="schedule.wedCheck" v-model="schedule.wedCheck">Wed</argon-checkbox>
              </div>
              <div class="col-4">
                <argon-input type="time" size="md" placeholder="Time" class="mb-0" v-model="schedule.wedStart" />
              </div>
              <div class="col-1 text-center">
                -
              </div>
              <div class="col-4">
                <argon-input type="time" placeholder="Time" class="mb-0" v-model="schedule.wedEnd" />
              </div>
            </div>

            <div class="row d-flex align-items-center mt-2">
              <div class="col-3">
                <argon-checkbox id="checkboxId" :checked="schedule.thuCheck" v-model="schedule.thuCheck">Thu</argon-checkbox>
              </div>
              <div class="col-4">
                <argon-input type="time" size="md" placeholder="Time" class="mb-0" v-model="schedule.thuStart" />
              </div>
              <div class="col-1 text-center">
                -
              </div>
              <div class="col-4">
                <argon-input type="time" placeholder="Time" class="mb-0" v-model="schedule.thuEnd" />
              </div>
            </div>

            <div class="row d-flex align-items-center mt-2">
              <div class="col-3">
                <argon-checkbox id="checkboxId" :checked="schedule.friCheck" v-model="schedule.friCheck">Fri</argon-checkbox>
              </div>
              <div class="col-4">
                <argon-input type="time" size="md" placeholder="Time" class="mb-0" v-model="schedule.friStart" />
              </div>
              <div class="col-1 text-center">
                -
              </div>
              <div class="col-4">
                <argon-input type="time" placeholder="Time" class="mb-0" v-model="schedule.friEnd" />
              </div>
            </div>

            <div class="row d-flex align-items-center mt-2">
              <div class="col-3">
                <argon-checkbox id="checkboxId" :checked="schedule.satCheck" v-model="schedule.satCheck">Sat</argon-checkbox>
              </div>
              <div class="col-4">
                <argon-input type="time" size="md" placeholder="Time" class="mb-0" v-model="schedule.satStart" />
              </div>
              <div class="col-1 text-center">
                -
              </div>
              <div class="col-4">
                <argon-input type="time" placeholder="Time" class="mb-0" v-model="schedule.satEnd" />
              </div>
            </div>

            <div class="row d-flex align-items-center mt-2">
              <div class="col-3">
                <argon-checkbox id="checkboxId" :checked="schedule.sunCheck" v-model="schedule.sunCheck">Sun</argon-checkbox>
              </div>
              <div class="col-4">
                <argon-input type="time" size="md" placeholder="Time" class="mb-0" v-model="schedule.sunStart" />
              </div>
              <div class="col-1 text-center">
                -
              </div>
              <div class="col-4">
                <argon-input type="time" placeholder="Time" class="mb-0" v-model="schedule.sunEnd" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="p-3 pt-0 pb-0">
        <div class="row text-start">
        </div>
      </div>
      <div class="mt-1 button-row d-flex p-3">

        <button
            class="mb-0 btn bg-gradient-light js-btn-prev"
            type="button"
            title="Prev"
            @click="emit('prevStep')"
        >
          Prev
        </button>
        <button
            class="mb-0 btn bg-gradient-dark ms-auto"
            type="button"
            title="Send"
            @click="handleSubmit"
        >
          Send
        </button>
      </div>
    </div>
  </div>
</template>

