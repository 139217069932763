<script setup>
import {onUnmounted, ref, computed} from 'vue';

import { useStore } from "vuex";
import { API_CONFIG } from "@/config.js";
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import FooterNav from "@/examples/Sidenav/FooterNav.vue";
import AppFooter from "@/examples/Footer.vue";
import {useRoute, useRouter} from "vue-router";

const store = useStore();
const route = useRoute();
const router = useRouter();
const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});


const layout = computed(() => store.state.layout);
const showSidenav = computed(() => store.state.showSidenav);
const showNavbar = computed(() => store.state.showNavbar);
const showFooter = computed(() => store.state.showFooter);
const showConfig = computed(() => store.state.showConfig);
const hideConfigButton = computed(() => store.state.hideConfigButton);
// Убираем слушатель события при уничтожении компонента

onUnmounted(() => {
  window.removeEventListener('resize');
});
// Определение размера экрана
const screenWidth = ref(window.innerWidth);
// Вычисляем, является ли размер экрана XS
const isVisibleOnXS = computed(() => screenWidth.value < 576);
// Слушатель изменения размера окна для обновления значения screenWidth
window.addEventListener('resize', () => {
  screenWidth.value = window.innerWidth;
});

// Отправляет токен из localStorage и проверяет ответ
const checkToken = async () => {
  let token = localStorage.getItem('token');
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  }
  // Отправить данные
 await fetch(`${API_CONFIG.baseURL}/api/v1/user/checkAuth`, requestOptions)
      .then(async response => {
        const data = await response.json()
        if (data.success !== true) {
          router.push('/');
        }
      }).catch(error => {
       router.push('/');
     });
};

checkToken();

</script>
<style>
@media (max-width: 575.98px) {
  .container-fluid {
    //padding: 8px !important;
  }
  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    width: 98% !important;
    padding-right: var(--bs-gutter-x, 0.5rem) !important;
    padding-left: var(--bs-gutter-x, 0.5rem) !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
</style>
<template>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>
  <sidenav v-if="showSidenav && !isVisibleOnXS" />
  <main class="main-content position-relative max-height-vh-100 h-100">
    <navbar v-if="showNavbar && !isVisibleOnXS" />
    <router-view  />
    <footer-nav v-show="showNavbar && isVisibleOnXS" :current-page="currentRouteName" :current-directory="currentDirectory" />
    <app-footer v-show="showFooter && !isVisibleOnXS" />
    <!--<configurator
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />-->
  </main>
</template>
