<script setup>
import { useI18n } from 'vue-i18n';

const i18n = useI18n({ useScope: 'global' });
const { t } = useI18n() // 't' это функция для выполнения перевода
// Метод для изменения языка
const setLanguage = (lang) => {
  i18n.locale.value = lang; // Изменяем текущую локаль
  localStorage.setItem('language', lang);
};
</script>
<template>
  <ul
    class="nav nav-footer justify-content-center justify-content-lg-end"
>

<li class="nav-item">
  <a class="nav-link pe-0 text-muted" style="cursor: pointer; font-weight: bold" @click="setLanguage('pt')">pt</a>
</li>
<li class="nav-item">
  <a class="nav-link pe-0 text-muted" style="cursor: pointer; font-weight: bold" @click="setLanguage('en')">en</a>
</li>
<li class="nav-item">
  <a class="nav-link pe-0 text-muted" style="cursor: pointer; font-weight: bold" @click="setLanguage('ru')">ru</a>
</li>
</ul>
</template>