<script setup>
</script>
<template>
  <div className="container-fluid py-5">
    <div class="card">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Client ID
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Status
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Quantity
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Total
            </th>

            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Yours
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Created
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="align-middle text-center text-sm">

                <div class="d-flex flex-column">
                  <span class="text-secondary text-sm">238917</span>
                </div>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="badge badge-dot me-4">
                <i class="bg-info"></i>
                <span class="text-dark text-xs">not activated</span>
              </span>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">3</p>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">130 €</span>
            </td>

            <td class="align-middle text-center">
              <span class="text-secondary text-sm">3.9 €</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">26/03/2024</span>
            </td>
          </tr>
          <tr>
            <td class="align-middle text-center text-sm">

              <div class="d-flex flex-column">
                <span class="text-secondary text-sm">234917</span>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="badge badge-dot me-4">
                <i class="bg-danger"></i>
                <span class="text-dark text-xs">not activated</span>
              </span>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">0</p>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">0 €</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">0 €</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">23/03/2024</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
