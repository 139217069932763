<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import LangBlock from "./components/Lang.vue";
const store = useStore();
const i18n = useI18n({ useScope: 'global' });
const { t } = useI18n() // 't' это функция для выполнения перевода

const isRTL = computed(() => store.state.isRTL);


// Метод для изменения языка
const setLanguage = (lang) => {
  localStorage.setItem('language', lang);
  i18n.locale.value = lang; // Изменяем текущую локаль
};

</script>
<style>
.nav-link {
  display: inline;
}
.nav-footer a {
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0.05rem;
}
</style>
<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0 align-self-start">
          <div class="row align-items-center justify-content-lg-between align-items-start">

          <div
            class="text-sm text-left copyright text-muted"
          >
            ©
            {{ new Date().getFullYear() }}, made with
            <i class="fa fa-heart text-danger"></i> by
            WoFooo.com
          </div>
          </div>
        </div>
        <div class="col-lg-6">
          <lang-block></lang-block>
          <ul class="nav nav-footer justify-content-center justify-content-lg-end text-center text-lg-end" style="display: block;">
            <li class="nav-item">
              <a href="/policy/terms-conditions" class="pe-0 text-muted" target="_blank">{{ t('footer.terms') }}</a>
            </li>
            <li class="nav-item">
              <a href="/policy/privacy" class="pe-0 text-muted" target="_blank">{{ t('footer.privacy') }}</a>
            </li>
            <li class="nav-item">
              <a href="/policy/cookie" class="pe-0 text-muted" target="_blank">{{ t('footer.cookie') }}</a>
            </li>
            <li class="nav-item">
              <a href="/policy/promotion-referral" class="pe-0 text-muted" target="_blank">{{ t('footer.affiliate') }}</a>
            </li>
            <li class="nav-item">
              <a href="/policy/data-deletion" class="pe-0 text-muted" target="_blank">{{ t('footer.delete') }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
