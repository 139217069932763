<script setup>
import {onBeforeMount, onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from 'vue-i18n'
import {API_CONFIG} from "@/config.js";
import {useRouter} from "vue-router";
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

const { t } = useI18n() // 't' это функция для выполнения перевода

const body = document.getElementsByTagName("body")[0];
const router = useRouter();
const store = useStore();

const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");
const geocodingClient = mbxGeocoding({ accessToken: API_CONFIG.MAPBOX_TOKEN});
const categories = ref([])

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
  store.state.showNavbar = true;
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
  store.state.showNavbar = true;
});
const cityOptions = ref([]);
const state = reactive({
  searchQuery: '',
  latitude: '',
  longitude: '',
  text: '',
  error: '',
  selectedCityName: '',
  selectedAddress: '',
  selectedPhone: '',
  editName: false,
  editPhone: false,
  editAddress: false,
  isHandWriteAddress: false,
  mapImageUrl: '',
  createdId: 0,
});

onMounted(async () => {
  if (localStorage.getItem('createdPlace')) {
    let placeReady = JSON.parse(localStorage.getItem('createdPlace'));
    state.selectedCityName = placeReady.name;
    state.selectedPhone = placeReady.phone;
    state.selectedAddress = placeReady.address;
    state.latitude = placeReady.latitude;
    state.longitude = placeReady.longitude;
    state.createdId = placeReady.id;
    selectedCategories.value = JSON.parse(localStorage.getItem('categories'))


    state.mapImageUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+FF0000(${state.longitude},${state.latitude})/${state.longitude},${state.latitude},18,0,0/1200x800?access_token=${API_CONFIG.MAPBOX_TOKEN}`;

    // Применение этой функции
    getBase64Image(state.mapImageUrl)
        .then(base64Image => state.base64Image = base64Image)
        .catch(err => console.error(err));
    // Обновление переменной state.mapImageUrl
  }
  let response = await fetch(`${API_CONFIG.baseURL}/api/v1/sale_place_types`) // Замените на URL вашего API
  let data = await response.json()
  categories.value = data.data
})
const nameEdit = () => {
  state.editName = true;
  state.isHandWriteAddress = true;
};

const phoneEdit = () => {
  state.editPhone = true;
};

const addressEdit = () => {
  state.editAddress = true;
  state.isHandWriteAddress = true;
};

let cityChoices;
let cityData = {};

const searchCity = () => {
  if (state.searchQuery.length < 3) {
    return;
  }
  const lang = localStorage.getItem('language') || 'en';
  const selectedCity = JSON.parse(localStorage.getItem('selectedCity'));
  let lon, lat;

  if (selectedCity && typeof selectedCity === 'object') {
    const cityKeys = Object.keys(selectedCity);

    if (cityKeys.length > 0) {
      const firstKey = cityKeys[0];
      const coordinates = selectedCity[firstKey];

      if (coordinates && typeof coordinates === 'object') {
        lon = coordinates.lon;
        lat = coordinates.lat;
      } else {
        lon = selectedCity.lon;
        lat = selectedCity.lat;
      }
    }
  }
console.log(lon, lat)
// Формирование URL
  let url = `${API_CONFIG.baseURL}/api/v1/google_maps_places?query=${state.searchQuery}&lang=${lang}`;
  if (lon !== undefined && lat !== undefined) {
    url += `&longitude=${lon}&latitude=${lat}`;
  }
  fetch(url)
      .then(response => response.json())
      .then(data => {
        cityOptions.value = data.map(prediction => {
          cityData[prediction.displayName] = {
            label: prediction.displayName,
            lat: prediction.latitude,
            lon: prediction.longitude,
            text: prediction.displayName,
            context: prediction.formattedAddress,
          };
          return {
            value: prediction.displayName,
            label: prediction.displayName + ' ' + prediction.formattedAddress,
          };
        });
      })
      .catch(error => {
        console.error('Error with Google Places API: ', error);
      });
};


async function getBase64Image(imgUrl) {
  const response = await fetch(imgUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}




const errorMessage = ref(null)
const errors = ref(null)

const handleCitySelection = (selectedCityData) => {

  // Store the selected city's details in localStorage
  localStorage.setItem('selectedPlace', JSON.stringify(cityData[selectedCityData.value]));

  // Empty the cityOptions array once a city is selected,
  // to clear the search results from the UI
  cityOptions.value = [];

  // optionally, you can populate the input field with the selected city's name
  state.searchQuery = selectedCityData.label;
  state.selectedCityName = cityData[selectedCityData.value].text;

  state.selectedAddress = cityData[selectedCityData.value].context;
  state.selectedPhone = cityData[selectedCityData.value].phone;
  localStorage.setItem('isSelectedPlace', 'yes');

  const { lat, lon } = cityData[selectedCityData.value];
  state.mapImageUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+FF0000(${lon},${lat})/${lon},${lat},18,0,0/1200x800?access_token=${API_CONFIG.MAPBOX_TOKEN}`;

  // Применение этой функции
  getBase64Image(state.mapImageUrl)
      .then(base64Image => state.base64Image = base64Image)
      .catch(err => console.error(err));
  // Обновление переменной state.mapImageUrl
};
const clearLocalStorage = () => {
  state.searchQuery = '';
  localStorage.removeItem('selectedPlace');
};


const selectedCategories = ref([]);

const handleCategorySelection = (category) => {
  if (selectedCategories.value.includes(category)) {
    selectedCategories.value = selectedCategories.value.filter(c => c !== category);
  } else {
    selectedCategories.value.push(category);
  }
  console.log(selectedCategories.value); // Add this line
  localStorage.setItem('categories', JSON.stringify(selectedCategories.value));
}
const emit = defineEmits(["nextStep"]);

const submitForm = async () => {
  // Получить данные из localStorage
  const selectedCityDataString = localStorage.getItem('selectedPlace');
  if (selectedCityDataString) {
    const selectedCityData = JSON.parse(selectedCityDataString);
    // Преобразование данных в нужный формат
    const dataToSend = {
      name: state.selectedCityName,
      phone: state.selectedPhone,
      address: state.selectedAddress,
      categories: JSON.stringify(selectedCategories.value),
      latitude: selectedCityData.lat,
      longitude: selectedCityData.lon,
      isHandWriteAddress: state.isHandWriteAddress,
      id: state.createdId
    };

    let token = localStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(dataToSend)
    };
    // Отправить данные
    let response = await fetch(`${API_CONFIG.baseURL}/api/v1/sale_places`, requestOptions)
        .then(async response => {
          const data = await response.json();
          if (data.data.id) {
            localStorage.setItem('createdPlace', JSON.stringify(data.data));
            emit('nextStep');
          }
        })
        .catch(error => {
          if (error.code === 1) {
            errors.value = error.errors;
          } else {
            errorMessage.value = error;
            console.error('There was an error!', error);
          }
        });
  } else {
    console.log('No data in localStorage with key "selectedCity"');
  }
};

</script>
<style scoped>
.btn-outline-success {
  margin-right: 6px;
}
.btn-success {
  margin-right: 6px;
}
.top-image {
  /* Задайте ширину и высоту, как вам требуется */
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
}
</style>
<template>
  <div
    class="mb-4 bg-white card multisteps-form__panel border-radius-xl js-active position-relative"
    data-animation="FadeIn"
  >
    <div class="row p-3 pb-0">
      <div class="mx-auto col-12">
        <h5 class="text-center font-weight-normal">
          Основная информация
        </h5>
        <p class="text-sm" style="text-align: left !important;">
          Давайте найдем ваше заведение в нашем справочнике, начните вводить название.
        </p>
      </div>
    </div>
    <div class="multisteps-form__content">
      <div class="mt-0 p-3 pt-0 pb-0">
        <div class="text-start">
          <input
            class="mb-0 multisteps-form__input form-control"
            v-model="state.searchQuery"
            @input="searchCity"
            type="text"
            placeholder="Поиск по названию вашего заведения"
          />

          <div
              v-for="option in cityOptions"
              :key="option.value"
              v-on:click="handleCitySelection(option)"
              class="search-result-item"
          >
            {{ option.label }}
          </div>

          <div class="text-center mt-0">
            <a href="#" class="text-primary text-xs">Не нашли свое заведение?</a>
          </div>
        </div>
      </div>
      <hr style="margin-bottom: 0 !important;">

        <div class="top-image" :style="{ backgroundImage: 'url(' + state.base64Image + ')' }"></div>



      <hr style="margin-top: 0 !important; margin-bottom: 8px !important;">
      <div class="p-3 pt-0 pb-0">
        <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label class="mb-0" style="margin-left: 0 !important;">Название</label>
            <input
              class="mb-3 multisteps-form__input form-control d-none"
              type="text"
              placeholder="Название"
              v-show="state.editName"
            />
            <input
              type="hidden"
              v-show="state.createdId"
            />
            <div class="selectedPlaceName">{{ state.selectedCityName }}</div>
            <a href="#" class="position-absolute bottom-0 end-0 m-2 mb-0 nameEdit" @click.prevent="nameEdit" style="cursor: pointer"><i class="fa fa-pencil"></i></a>
          </div>
        </div>
      </div>
      <hr style="margin-top: 8px !important; margin-bottom: 8px !important;">
      <div class="p-3 pt-0 pb-0">
        <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label class="mb-0" style="margin-left: 0 !important;">Телефон</label>
            <input
                class="multisteps-form__input form-control d-none"
                type="email"
                placeholder="Телефон"
                v-show="state.editPhone"
            />
            <div class="selectedPlacePhone">{{ state.selectedPhone }}</div>
            <a href="#" class="position-absolute bottom-0 end-0 m-2 mb-0 phoneEdit" @click.prevent="phoneEdit" style="cursor: pointer"><i class="fa fa-pencil"></i></a>
          </div>
        </div>
      </div>
      <hr style="margin-top: 8px !important; margin-bottom: 8px !important;">
        <div class="p-3 pt-0 pb-0">
          <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label class="mb-0" style="margin-left: 0 !important;">Адрес</label>
            <input
                class="multisteps-form__input form-control d-none"
                type="text"
                placeholder="Адрес"
                v-show="state.editPhone"
            />
            <div class="selectedPlaceAddress">{{ state.selectedAddress }}</div>
            <a href="#" class="position-absolute bottom-0 end-0 m-2 mb-0 addressEdit" @click.prevent="phoneEdit" style="cursor: pointer"><i class="fa fa-pencil"></i></a>
          </div>
          </div>
        </div>

      <hr style="margin-top: 8px !important; margin-bottom: 8px !important;">

      <div class="p-3 pt-0 pb-0">
        <div class="row text-start">
          <div class="col-12 position-relative mb-2">
            <label style="margin-left: 0 !important;">Категория заведения</label>
            <p class="text-xs font-italic">Можно выбрать несколько.</p>
            <div>
              <button
                  v-for="category in categories"
                  :key="category.id"
                  :class="[`btn btn-xs`, (selectedCategories || []).includes(category.id) ? 'btn-success' : 'btn-outline-success']"
                  @click.prevent="handleCategorySelection(category.id)"
              >
                {{category.name}}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="p-3 pt-0 pb-0">
        <div class="row text-start">
        </div>
      </div>
      <div class="mt-1 button-row d-flex p-3">
        <button
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="submitForm();"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
