/**
=========================================================
* Vue Argon Dashboard 2 PRO - v4.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import { createI18n } from 'vue-i18n'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ru from "./locale/ru.json";
import en from "./locale/en.json";
import pt from "./locale/pt.json";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'


const messages = {
    pt,
    ru,
    en
}

var language = window.navigator.userLanguage || window.navigator.language;
var shortLang = language.substr(0, 2);
if (localStorage.getItem('language')) {
    shortLang = localStorage.getItem('language');
}
const i18n = createI18n({
    legacy: false,
    locale: shortLang,
    fallbackLocale: 'en',
    messages,
})

const appInstance = createApp(App);
appInstance.component('v-select', vSelect)
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.use(i18n);
appInstance.mount("#app");
