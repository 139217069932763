import { createRouter, createWebHistory } from "vue-router";

import Main from "../views/wf/main.vue";
import Offers from "../views/wf/offers.vue";
import Favorite from "../views/wf/favorite.vue";
import OffersHistory from "../views/wf/offers/history.vue";
import OffersOffer from "../views/wf/offers/offer.vue";
import Account from "../views/wf/account.vue";
import BasicInfo from "../views/wf/account/basicInfo.vue";
import affiliateProgram from "../views/wf/account/affiliateProgram.vue";
import changePassword from "../views/wf/account/changePassword.vue";
import haveAPoint from "../views/wf/account/haveAPoint.vue";
import changeCity from "../views/wf/account/changeCity.vue";
import Transactions from "../views/wf/transactions.vue";
import Sellers from "../views/wf/sellers.vue";
import SellersSeller from "../views/wf/sellers/seller.vue";
import Clients from "../views/wf/clients.vue";
import ClientsClient from "../views/wf/clients/client.vue";
import Seller from "../views/wf/seller.vue";
import SellerQuickStart from "../views/wf/seller/quick-start.vue";
import SellerPointNew from "../views/wf/seller/point.vue";
import SellerPointEdit from "../views/wf/seller/point.vue";
import SellerDishNew from "../views/wf/seller/dish.vue";
import SellerDishEdit from "../views/wf/seller/dish.vue";
import SellerSale from "../views/wf/seller/sale.vue";
import SellerOrders from "../views/wf/seller/orders.vue";
import Signup from "../views/wf/signup/Basic.vue";
import Signin from "../views/wf/signin/Basic.vue";
import AuthMethod from "../views/wf/auth/Method.vue";
import AuthLocation from "../views/wf/auth/Location.vue";
import AuthCity from "../views/wf/auth/City.vue";
import AuthEmail from "../views/wf/auth/Email.vue";
import AuthCode from "../views/wf/auth/Code.vue";
import AuthToken from "../views/wf/auth/TokenPage.vue";
import CookiePolicy from "../views/wf/policy/CookiePolicy.vue";
import DataDeletionInstructions from "../views/wf/policy/DataDeletionInstructions.vue";
import PrivacyPolicy from "../views/wf/policy/PrivacyPolicy.vue";
import PromotionReferralTerms from "../views/wf/policy/PromotionReferralTerms.vue";
import TermsConditions from "../views/wf/policy/TermsConditions.vue";

import Landing from "../views/dashboards/Landing.vue";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import VRDefault from "../views/dashboards/vr/VRDefault.vue";
import VRInfo from "../views/dashboards/vr/VRInfo.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import Pricing2 from "../views/pages/Pricing2.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";
import {useI18n} from "vue-i18n";


const routes = [

  {
    path: "/policy/cookie",
    name: "CookiePolicy",
    component: CookiePolicy
  },
  {
    path: "/policy/data-deletion",
    name: "DataDeletionInstructions",
    component: DataDeletionInstructions
  },
  {
    path: "/policy/privacy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: "/policy/promotion-referral",
    name: "PromotionReferralTerms",
    component: PromotionReferralTerms
  },
  {
    path: "/policy/terms-conditions",
    name: "TermsConditions",
    component: TermsConditions
  },
  {
    path: "/auth",
    name: "AuthMethod",
    component: AuthMethod
  },
  {
    path: "/auth/location",
    name: "AuthLocation",
    component: AuthLocation
  },
  {
    path: "/auth/city",
    name: "AuthCity",
    component: AuthCity
  },
  {
    path: "/auth/email",
    name: "AuthEmail",
    component: AuthEmail
  },
  {
    path: "/auth/code",
    name: "AuthCode",
    component: AuthCode
  },
  {
    path: "/auth/token",
    name: "AuthToken",
    component: AuthToken
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin
  },
  {
    path: "/main",
    name: "main",
    component: Main
  },
  {
    path: "/offers",
    name: "offers",
    component: Offers
  },
  {
    path: "/offers/:id",
    name: "infoAboutOffer",
    component: OffersOffer
  },
  {
    path: "/favorite",
    name: "favorite",
    component: Favorite
  },
  {
    path: "/offers/history",
    name: "history",
    component: OffersHistory
  },
  {
    path: "/account",
    name: "account",
    component: Account
  },
  {
    path: "/account/basic-info",
    name: "basicInfo",
    component: BasicInfo
  },
  {
    path: "/account/affiliate",
    name: "affiliateProgram",
    component: affiliateProgram
  },
  {
    path: "/account/password",
    name: "changePassword",
    component: changePassword
  },
  {
    path: "/account/point",
    name: "haveAPoint",
    component: haveAPoint
  },
  {
    path: "/account/change_city",
    name: "changeCity",
    component: changeCity
  },
  {
    path: "/transactions",
    name: "transactions",
    component: Transactions
  },
  {
    path: "/sellers",
    name: "sellers",
    component: Sellers
  },
  {
    path: "/sellers/:id",
    name: "infoAboutSeller",
    component: SellersSeller
  },
  {
    path: "/clients",
    name: "clients",
    component: Clients
  },
  {
    path: "/clients/:id",
    name: "infoAboutClient",
    component: ClientsClient
  },
  {
    path: "/seller",
    name: "seller",
    component: Seller
  },
  {
    path: "/seller/quick-start",
    name: "SellerQuickStart",
    component: SellerQuickStart
  },
  {
    path: "/seller/point",
    name: "creatingPoint",
    component: SellerPointNew
  },
  {
    path: "/seller/point/:id",
    name: "editingPoint",
    component: SellerPointEdit
  },
  {
    path: "/seller/dish",
    name: "creatingDish",
    component: SellerDishNew
  },
  {
    path: "/seller/dish/:id",
    name: "editingDish",
    component: SellerDishEdit
  },
  {
    path: "/seller/sale",
    name: "sale",
    component: SellerSale
  },
  {
    path: "/seller/orders",
    name: "orders",
    component: SellerOrders
  },



















  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default
  },
  {
    path: "/dashboards/landing",
    name: "Landing",
    component: Landing
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome
  },
  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing
  },
  {
    path: "/",
    name: "Pricing Page2",
    component: Pricing2
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});


router.afterEach(() => {
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100); // Добавляем задержку в 100 миллисекунд
});



export default router;
