import { reactive } from 'vue';

// Создаем реактивное состояние
const userData = reactive({
    name: '',
    sname: '',
    email: '',
    avatar: '',
    phone: '',
    city: '',
    is_partner: false,
    is_owner: false
});

// Создаем методы для изменения состояния
const setNameUser = (newName) => {
    userData.name = newName;
};
const setIsPartner = (newIsPartner) => {
    console.log(newIsPartner)
    userData.is_partner = newIsPartner;
};
const setIsOwner = (newIsOwner) => {
    userData.is_owner = newIsOwner;
};

const setSnameUser = (newSname) => {
    userData.sname = newSname;
};

const setEmailUser = (newEmail) => {
    userData.email = newEmail;
};
const setAvatarUser = (newAvatar) => {
    userData.avatar = newAvatar;
};
const setPhoneUser = (newPhone) => {
    userData.phone = newPhone;
};
const setCityUser = (newCity) => {
    userData.city = newCity;
};

// Экспортируем состояние и методы
export { userData, setNameUser, setSnameUser, setEmailUser, setAvatarUser, setCityUser, setPhoneUser, setIsPartner, setIsOwner };
