<script setup>
import { ref } from 'vue';
//import {MapboxGeolocateControl, MapboxMap, MapboxGeocoder} from '@studiometa/vue-mapbox-gl';
//import 'mapbox-gl/dist/mapbox-gl.css';
//import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';
const mapCenter = ref([-8.419047595106012, 41.54356488687256]);
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">

        <div id="profile" class="card card-body mt-4">

        </div>
      </div>
    </div>
  </div>
</template>
