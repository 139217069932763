// ./src/views/TokenPage.vue
<template>
  <div>
    Обработка...
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

onMounted(() => {
  const token = route.query.token;
  if(token) {
    localStorage.setItem('token', token);
    router.push({ name: 'AuthLocation'});
  } else {
    // Обработка ситуации, когда token отсутствует в строке запроса
    alert('Ошибка установки токена');
  }
});
</script>