<script setup>
import { onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import AccordionItem from "@/views/pages/components/AccordionItem.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import { useI18n } from 'vue-i18n'

const { t } = useI18n() // 't' это функция для выполнения перевода
const store = useStore();

onMounted(() => {
  store.state.showSidenav = false;
  store.state.showNavbar = false;
  store.state.showFooter = false;
  setNavPills();
});
onBeforeUnmount(() => {
  store.state.showSidenav = true;
  store.state.showNavbar = true;
  store.state.showFooter = true;
  if (store.state.isPinned === false) {
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    store.state.isPinned = true;
  }
});
</script>
<template>
  <navbar />
  <div
      class="page-header position-relative"
      :style="{
      backgroundImage:
        'url(' + require('../../../assets/img/bg.jpg') + ')',
      backgroundSize: 'cover',
    }"
  >
    <div class="container pb-2 pb-lg-2 pt-6 postion-relative z-index-2">
      <div class="row">
        <div class="mx-auto text-center col-md-6 mt-4">
          <h3 class="text-white" style="padding-bottom: 6px !important;">{{ t('policy.delete.dataDeletionInstructions') }}</h3>
        </div>
      </div>
    </div>
  </div>

  <div class="container my-6">

    <div class="row">
      <div class="mx-auto col-md-10">
        <div id="accordionRental" class="accordion">
          <accordion-item
              accordion-id="headingOne"
              collapse-id="collapseOne"
              active
          >
            <template #question>{{ t('policy.delete.dataDeletionInstructions') }}</template>
            <template #answer>
              {{ t('policy.delete.dataDeletionInstructionsText') }}
            </template>
          </accordion-item>
          <accordion-item accordion-id="headingTwo" collapse-id="collapseTwo">
            <template #question>{{ t('policy.delete.requestingDataDeletion') }}</template>
            <template #answer>
              <span v-html="t('policy.delete.requestingDataDeletionText')"></span>
            </template>
          </accordion-item>

          <accordion-item
              accordion-id="headingThree"
              collapse-id="collapseThree"
          >
            <template #question
            >{{ t('policy.delete.processingRequest') }}</template
            >
            <template #answer>
              {{ t('policy.delete.processingRequestText') }}
            </template>
          </accordion-item>

          <accordion-item accordion-id="headingFour" collapse-id="collapseFour">
            <template #question>{{ t('policy.delete.importantConsiderations') }}</template>
            <template #answer>
              <span v-html="t('policy.delete.importantConsiderationsText')"></span>
            </template>
          </accordion-item>

          <accordion-item
              accordion-id="headingFifth"
              collapse-id="collapseFifth"
          >
            <template #question>{{ t('policy.delete.contactUs') }}</template>
            <template #answer>
              {{ t('policy.delete.contactUsText') }}
            </template>
          </accordion-item>

        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>
