<script setup>
import { ref } from 'vue'
const selected = ref(null)
const options = ['Москва', 'Париж', 'Милан']
</script>
<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="col-12 col-lg-12 mx-auto mb-12">
              <div class="card">
                <div class="card-body">
                  /main<br>
                  посадочная страница после авторизации, содержит на себе виджеты по каким-то общим показателям со ссылками на другие страницы
                  <div>
                    <v-select :options="options" v-model="selected"></v-select>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
