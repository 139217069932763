<script setup>

import { useI18n } from 'vue-i18n'
const { t } = useI18n() // 't' это функция для выполнения перевода

import havaAPoint from "@/views/wf/account/components/haveAPoint.vue";
import router from "@/router";
import ProfileAccount from "@/views/wf/account/components/profileAccount.vue";
import {onMounted, ref} from "vue";
import {API_CONFIG} from "@/config";

let name = 'Maksim'; // создаем реактивные свойства для хранения информации о пользователе
let sname = 'Trushin';
let email = 'max@trushin.me';

const goTo = () => {
  router.push({ name: 'account'})
};
</script>
<template>
  <section>


    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">

          <div id="profile" class="card card-body mt-4">
            <profile-account :name="name" :sname="sname" :email="email" />
          </div>


          <div class="py-4">
            <div class="mt-2 row">
              <div id="basic-info" class=" col-12 sm-6 col-md-6 col-xl-3 ">
                <div class="card h-100">
                  <div class="position-absolute top-0 end-0 m-2 mt-1">
                    <div class="cursor-pointer" @click="goTo">
                      <i class="fas fa-times text-black-50 fs-5 p-3"></i>
                    </div>
                  </div>
                  <hava-a-point></hava-a-point>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>