<script setup>
import { ref, watch } from "vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { useI18n } from 'vue-i18n';
import { userData, setIsPartner } from '@/store/userStore.js';
const { t } = useI18n(); // 't' это функция для выполнения перевода
import { API_CONFIG } from "@/config.js";

const errorMessage = ref(null);
const errors = ref(null);

// Инициализация состояния на основе значения из userStore
const formAffiliateUserUpdate = ref({
  is_partner: userData.is_partner
});

const token = localStorage.getItem('token');

const submitFormUpdateAffiliateUser = () => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    body: JSON.stringify(formAffiliateUserUpdate.value)
  };

  fetch(`${API_CONFIG.baseURL}/api/v1/user/affiliate`, requestOptions)
      .then(async response => {
        const data = await response.json();
        if (data.data.id) {
          setIsPartner(formAffiliateUserUpdate.value.is_partner); // обновляем значение в userData
        }
      })
      .catch(error => {
        if (error.code === 1) {
          errors.value = error.errors;
        } else {
          errorMessage.value = error;
          console.error('There was an error!', error);
        }
      });
};

// Синхронизация состояния при изменении userData.is_partner
watch(() => userData.is_partner, (newVal) => {
  formAffiliateUserUpdate.value.is_partner = newVal;
});

// Обновление userData.is_partner при изменении formAffiliateUserUpdate
watch(formAffiliateUserUpdate, (newVal) => {
  setIsPartner(newVal.is_partner);
});

</script>

<template>
  <section>
    <div class="card-header">
      <h6 class="mb-0">{{ t('account.affiliateProgram') }}</h6>
    </div>
    <div class="card-body pt-0">
      <label class="form-label mt-2">{{ t('account.clients') }}</label>
      <p class="mb-0 ps-2" style="font-size: 0.9rem">
        {{ t('account.earnFromClient') }}<br>
        <!--<router-link class="mb-0 btn btn-link pe-3 ps-0 ms-auto" to="#">{{ t('account.moreInfo') }}</router-link>-->
      </p>
      <ul class="list-group">
        <li class="px-0 border-0 list-group-item">
          <form id="formAffiliateUserUpdate">
            <argon-switch
                id="is_partner"
                name="is_partner"
                value="true"
                v-model="formAffiliateUserUpdate.is_partner"
                :checked="formAffiliateUserUpdate.is_partner"
                @change="val => { formAffiliateUserUpdate.is_partner = val.target.checked; submitFormUpdateAffiliateUser(); }"
                label-class="mb-0 text-body ms-3 text-truncate w-80"
            >{{ t('account.enableThisProgramm') }}
            </argon-switch>
          </form>
        </li>
      </ul>
      <label class="form-label mt-4 mb-3">{{ t('account.spesialLink') }}</label>
      <div class="d-flex align-items-center">
        <div class="form-group">
          <div class="input-group bg-gray-200" style="width: 100%">
            <input
                class="form-control form-control-sm"
                :value="$t('account.yourSpecialLinkWillBeHere')"
                type="text"
                disabled
                onfocus="focused(this)"
                onfocusout="defocused(this)"
            />
            <span
                class="input-group-text bg-transparent"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title
                :data-bs-original-title="$t('account.yourSpecialLinkWillBeHere')"
                :aria-label="$t('account.yourSpecialLinkWillBeHere')"
            >
              <i class="ni ni-key-25"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
