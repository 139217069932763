//export const API_CONFIG = {
//    baseURL: "https://api.wofooo.com",
//    MAPBOX_TOKEN: "pk.eyJ1IjoibWF4LW1hcnMiLCJhIjoiY2x1cHRhdTBwMXEwaTJrcDdmczBxYnV6diJ9.XHWPHvqUGZhXHnIHSKkArQ",
//};


export const API_CONFIG = {
    baseURL: process.env.VUE_APP_API_URL,
    MAPBOX_TOKEN: process.env.VUE_APP_MAPBOX_TOKEN,
    GOOGLE_MAP_API_TOKEN: process.env.VUE_APP_GOOGLE_MAP_API_TOKEN,
    STRIPE_PUBLISH_KEY: process.env.VUE_APP_STRIPE_PUBLISH_KEY,
};
