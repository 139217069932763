<template>
  <form id="payment-form">
    <div id="payment-element">
      <!-- Stripe will create form elements here -->
    </div>
    <button type="submit" @click="handleSubmit">Pay via Stripe</button>
  </form>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import { API_CONFIG } from '@/config.js';

const token = ref(null);
const stripe = ref(null);
const elements = ref(null);
const userToken = localStorage.getItem('token');

const props = defineProps({
  type: Number,
  summ: Number,
  productId: Number,
});


const initializeStripe = async () => {
  try {
    let summa = props.summ.replace(/[^0-9.]+/g, '');
    const response = await fetch(`${API_CONFIG.baseURL}/api/v1/payment/initiate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      body: JSON.stringify({
        amount: summa,
        currency: 'EUR'
      })
    });

    const data = await response.json();
    token.value = data.token; // Use to identify the payment

    stripe.value = await loadStripe(API_CONFIG.STRIPE_PUBLISH_KEY);
    const options = {
      clientSecret: data.client_secret,
    };

    elements.value = stripe.value.elements(options);
    const paymentElement = elements.value.create('payment');
    paymentElement.mount('#payment-element');
  } catch (error) {
    console.error('Error during payment initiation:', error);
  }
};

watch(() => props.productId, (newVal) => {
  console.log(newVal)
  if (newVal > 0) {
    initializeStripe();
  }
});

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const {error} = await stripe.value.confirmPayment({
      elements: elements.value,
      redirect: 'if_required'
    });

    const endpoint = error ? 'failure' : 'complete';
    const body = error
        ? {token: token.value, code: error.code, description: error.message}
        : {token: token.value};

    await fetch(`${API_CONFIG.baseURL}/api/v1/payment/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      body: JSON.stringify(body)
    });
  } catch (error) {
    console.error('Error during payment confirmation:', error);
  }
};
</script>
